// import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { Form, Input, TextArea, FileUpload, Model, CheckBox } from "../../Components";
import { getCategoryList, updateCategory } from "../../Services/Service";
import { uploadPublic } from "../../Services/FileUploadService";
import { onFailure } from "../../util/helper";
import { OptionList } from "../../Components/Select";
import { useState, useEffect } from "react";
import { Select } from "../../Components";
import Swal from "sweetalert2";
import { ASSET_URL } from "../../constant";

interface CategoryInterface {
  show: boolean;
  handleClose: () => void;
  editValue: any;
  setCategoryData: (a: any, b: any) => void;
  setopenModel: (a: boolean) => void;
  category: string[];
}

const EditCategoryPage = ({
  show,
  handleClose,
  setCategoryData,
  editValue,
  setopenModel,
  category,
}: // value,
CategoryInterface) => {
  const [options, setOptions] = useState<OptionList>([
    {
      label: "",
      value: "",
    },
  ]);

  useEffect(() => {
    const option: OptionList = category.map((cat: any) => ({
      label: cat.name,
      value: cat._id,
    }));
    setOptions(option);
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Category Updated Successfully",
      icon: "success",
    });

    setopenModel(false);
    getCategoryList(setCategoryData, () => {});
  };
  return (
    <>
      <Model
        centered={true}
        title="Edit Category"
        size="lg"
        show={show}
        backdrop="static"
        handleClose={handleClose}
      >
        <div className="row">
          <div className="col-md-12">
            <Form
              initialValues={editValue}
              onSubmit={(values: any) => {
                updateCategory(values, onSuccess, onFailure);
              }}
              render={({ handleChange }: any) => {
                return (
                  <>
                    <div className="row">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="row ">
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="name"
                                label="Name"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-12 p-2">
                              <Input
                                type="text"
                                name="englishTitle"
                                label="English Name"
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="seo.title"
                                label="SEO title"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="seo.description"
                                label="SEO Description"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-12 ">
                              <Input
                                  name="seo.metaTags[0].property"
                                  id="seo.metaTags[0].property"
                                  label="Keywords"
                                  onChange={handleChange}
                                  // required
                                  />
                            </div>
                            <div className="col-md-12 ">
                              <Input
                                  name="seo.metaTags[0].content"
                                  id="seo.metaTags[0].content"
                                  label="Content"
                                  onChange={handleChange}
                                  // required
                                  />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 ">
                              <Select
                                options={options}
                                isMulti={true}
                                id="parent"
                                name="parent"
                                label="Parent Category"
                                placeholder="Multi Select Parent"
                              />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 ">
                              <TextArea
                                name="description"
                                id="description"
                                label="Description"
                                row={3}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 ">
                              <FileUpload
                                accept={["image/png", "image/jpeg"]}
                                type="single"
                                maxSize={"3mb"}
                                name={"image"}
                                upload={uploadPublic}
                                assetUrl={ASSET_URL}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                    <CheckBox
                            name="active"
                            id="active"
                            label="Active"
                          />
                      <Button className="btn btn-info" type="submit">
                        <AiFillCheckCircle />
                        Update
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </Model>
    </>
  );
};
export default EditCategoryPage;
