import React, { useEffect, useState } from 'react'
import { Button, FormCheck, ListGroup, ListGroupItem } from 'react-bootstrap';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { handleInputChange } from 'react-select/dist/declarations/src/utils';
import Swal from 'sweetalert2';
import { BackButton, Input, Select, Form, CheckBox } from '../../Components'
import { Visual_CategoryCreate } from '../../RouteConstant';
import { getCategoryList, getVisualCateoryList, createVisualCategory } from '../../Services/Service';
import { onFailure } from '../../util/helper';

const Createvisualcategory = () => {

const history = useHistory();
  const [categories, setCategories] = useState<any[]>([]);
  const [vsCategories, setVisualCategory] = useState<any[]>([]);
  const [data, setData]: any = useState([])
  let tempCateg: any;
  const onsubmit = (values: any) => {
    // categories => isActive filter
    // array => backend
    const arrayBackend = values.categories.filter((val: any) => val.isActive === true)

    arrayBackend.forEach((element: any, index: number) => {
      element.sequence = index + 1;

    });

    // values.categories.forEach((cat:any) => {
    //   arrayBackend.forEach((vsc:any)=>{
    //     if(vsc.category._id === cat._id) {
    //      cat.isActive = vsc.isActive;
    //      cat.sequence = vsc.sequence;
    //       }
    //   });
    // });
    // setCategories(values.categories);
    // setVisualCategory(arrayBackend);

    const backendPayload: any = [];
    arrayBackend.forEach((cat: any) => {
      let payload: any = {};
      payload.category = cat._id;
      payload.isActive = cat.isActive;
      payload.sequence = cat.sequence;
      backendPayload.push(payload);
    });
    createVisualCategory(onCreateSuccess, onFailure, backendPayload)

  }
  const onVscSuccess = (Res: any) => {
    let tempVsc = Res;
    tempCateg.forEach((cat: any) => {
      tempVsc.forEach((vsc: any) => {
        if (vsc.category._id === cat._id) {
          cat.isActive = vsc.isActive;
          cat.sequence = vsc.sequence;
        }
      });
    });
    setCategories(tempCateg);

  }
  useEffect(() => {
    getCategoryList(onSuccess, onFailure)
  }, []);
  const onCreateSuccess = (res: any) =>{
  }

  const onSuccess = (Res: any) => {
    tempCateg = Res.data;
    setCategories(tempCateg);
    getVisualCateoryList(onVscSuccess, onFailure);
  }
  // useEffect(() => {
  //   const getCategories = async () => {
  //     const categories = await getCategoryList(onSuccess,onFailure)
  //     const vsCategories = 
  //     console.log('categories: ', categories)
  //     console.log('vsCategories: ', vsCategories)
  //     // get visual stories categories
  //     // vsc => cat => isActive and seq
  //     // 
  //     categories.forEach((cat: any) =>{
  //       vsCategories.forEach( (vsc: any) => {
  //         if(vsc.category._id === cat._id) {
  //           cat.isActive = vsc.isActive;
  //           cat.sequence = vsc.sequence;
  //         }
  //       });
  //     });
  //     console.log('after loop categories: ', categories)
  //     setCategories(categories);
  //     setVisualCategory(vsCategories);
  //   };

  //   getCategories(); // run it, run it

  //   return () => {
  //     // this now gets called when the component unmounts
  //   };
  // });
  const handleCheckChange = (event: any, cat: any) => {
    //console.log("vals:",event.target.value);
    const activeCat = event.target.checked;
    let oldCategories: any = categories.map((oldCat: any) => {
      if (oldCat._id === cat._id) {
        oldCat.isActive = activeCat;
      }
    });
    setCategories(oldCategories);
  }

  const handleSeqChange = (event: any, cat: any) => {
    const seqVal = event.target.value;
    cat.sequence = seqVal;
    let oldCategories: any = categories.filter((oldCat: any) => oldCat._id !== cat._id);
    //setCategories([...oldCategories, cat]);
  }


  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div>
          <div className='app-title'>
            <h1>Add Visualcategory</h1>
          </div>
          <Form
            initialValues={{
              categories
            }}
            onSubmit={(values: any) => {
              onsubmit(values)
            }
            }
            render={({ values }: any) => {
              return (
                <div>
                  {categories.map((cat: any, i: number) => (
                    <div className='row' >
                      <div className="col-md-3">
                        <CheckBox
                          name={`categories.${i}.isActive`}
                          id={cat._id}
                          label={cat.name}
                          checked={cat.isActive}
                        />
                      </div>
                      {/* <div className="col-md-3">
                        <Input
                          type='text'
                          name={`categories.${i}.sequence`}
                          placeholder='Enter Sequence'
                          value={cat.sequence}
                        />
                      </div> */}
                    </div>
                  ))}
                  <Button
                    className="btn btn-success mt-5"
                    type='submit'
                  >
                    <AiFillCheckCircle />
                    SUBMIT
                  </Button>
                </div>)
            }}

          />
        </div>
      </main>
    </>
  )
}

export default Createvisualcategory;