import { Field, FieldProps } from 'formik';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Form, Input, PaginatedTable, Paginator } from '../../Components';
import { RichTextEditor } from '../../Components/rich-text-editor/rich-text-editor';
import { ASSET_URL } from '../../constant';
import { uploadPublic } from '../../Services/FileUploadService';
import { createLiveBlogComment, deleteArticleLiveComment, getCms, getLiveBlogCommentryList, updateLiveBlogComment } from '../../Services/Service';
import { onFailure } from '../../util/helper';
import { ActionInterface } from '../../Components/BasicTable';
import { useHistory, useParams } from 'react-router-dom';
import { LiveBlog_Create, LiveBlog_EDIt } from '../../RouteConstant';
import Swal from 'sweetalert2';
const CreateLiveBlog = () => {
  const { id: _id } = useParams<any>();
  const history = useHistory();
  const [articlep, setArticle] = useState<any>({});
  const [initialValues, setInitialValues] = useState({
    article: _id,
    commentTitle: '',
    description: '',
    isShow: true
  });

  const [tableData, setTableData] = useState<any>([]);
  React.useEffect(() => {
    getCms(_id, onSuccess, onFailure);
    getLiveBlogCommentryList(onLiveCommentrySuccess, onFailure, { article: _id, isShow: true });
  }, [tableData._id]);

  const onSuccess = (res: any) => {
    setArticle(res.data);
  }
  const onLiveCommentrySuccess = (Res: any) => {
    let liveCommentList = Res;
    setTableData(liveCommentList);
  }
  const onCreateSuccess = (res: any) => {
    Swal.fire(
      'Created',
      'Your LiveComment has been Created.',
      'success'
    ).then(() => history.push(LiveBlog_Create.replace(":id", _id)));
  }
  const headings = [
    { key: "commentTitle", text: "LiveCommentTitle" },
    { key: "description", text: "Description" },
    { key: "isShow", text: "Status" }
  ]
  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => history.push(LiveBlog_EDIt.replace(":id", data._id)),
    },
    {
      text: "Hide",
      trigger: (data: any) => {
        data.isShow = !data.isShow
        updateLiveBlogComment(data, onHideSuccess, onFailure);
      }
    },
    {
      text: "Delete",
      trigger: (data: any) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        })
          .then((result) => {
            if (result.isConfirmed) {
              deleteArticleLiveComment(data._id, onDelSuccess, onFailure);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          }).then(() => history.push(`liveblog/create/${_id}`));
      },
    },
  ];
  const onDelSuccess = (res: any) => {
    Swal.fire({
      "title": "LiveCommentDeleted Successfully",
      "icon": "warning"
    }
    ).then(() => {
      history.push(`/liveblog/create/${_id}`);
    })
  }
  const onHideSuccess = (res: any) => {
    Swal.fire(
      'Hide!',
      'Your Live Comment has been hide successfully.',
      'success'
    ).then(() =>history.push(`${history.location.pathname}`))
  }

  return (<>
    <main className='app-content'>
      <div className='app-title'>
        <h3>{articlep.title}</h3>
      </div>
      <div className='tile'>
        <div>
          <div className="app-title">
            Add Live Blog Commentry
          </div>
          <div className="row">
            <div className="col-md12">
              <Form
                onSubmit={
                  (values: any) => {
                    createLiveBlogComment(values, onCreateSuccess, onFailure);
                  }
                }
                initialValues={initialValues}
                render={({ handleChange, values }: any) => {
                  return (
                    <>
                      <div className="row">
                        <div className="form-group">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12 p-2">
                                <Input
                                  type="text"
                                  name={`commentTitle`}
                                  label="Title"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-12 p-2">
                                <Field
                                  name={`description`}
                                  render={({
                                    form,
                                    field,
                                  }: FieldProps<
                                    number | string
                                  >) => {
                                    return (
                                      <RichTextEditor
                                        id={`description`}
                                        name={`description`}
                                        label="Content"
                                        required
                                        maxHeight={600}
                                        uploadFile={uploadPublic}
                                        getAssetUrl={ASSET_URL}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center uvs p-2">
                        <Button
                          className="btn btn-success mr-2"
                          type="submit" disabled={false}
                        >
                          <AiFillCheckCircle />
                          Save
                        </Button>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tile">
        <div className="tile-body table-responsive">
          <PaginatedTable
            headings={headings}
            data={tableData}
            totalSize={5}
            pageSize={2}
            actionItems={actionItems}
            renderPager={() => (
              <Paginator
                totalPages={Math.ceil(5 / 2)}
                onPageChange={() => { }}
                page={1}
              />
            )}
          >
          </PaginatedTable>
        </div>
      </div>
    </main>
  </>
  )
}
export default CreateLiveBlog;