import { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { AiFillFilter } from "react-icons/ai";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Form, Input, PaginatedTable, Paginator, Select } from '../../Components';
import { ActionInterface } from '../../Components/BasicTable';
import { SMALL_PAGE_SIZE } from '../../constant';
import {Create_Visualstory, Edit_Visualstory} from "../../RouteConstant";
import { getCategoryList, getVisualstoriesList, updateVisualstory } from '../../Services/Service';
import { onFailure } from '../../util/helper';
const Managevisualstories = () => {
  const history = useHistory();
  const[data,setData] = useState({list:[],total:0});
  const[showFilter,setShowFilter] = useState(false);
  const[currentpage,SetCurrentPage] = useState(1);
  const[options,setCategoryOptions] = useState([]);
  let listParams: any = { populate:"user_f=name, categories_f=name" };

  useEffect(()=>{
    getVisualstoriesList(onSuccess, onFailure, listParams);
    getCategoryList(onCategorySuccess,onFailure);
  },[]);
  const onSuccess = (res:any)=>{
    const list = res.data.list.map((i:any)=>{
      i.isActive = i.isActive ? 'Active':'Inactive' 
      return i
    })
    res.data.list = list
    setData(res.data);
  }

const onSuccessUpdate = (res: any) => {
    Swal.fire({
      title: "Visual Story Updated Successfully",
      icon: "success",
    }).then(() => {
      getVisualstoriesList(onSuccess, onFailure, { ...listParams, page: currentpage });
    });
  };

  const headings = [
    {
      key: "title",
      text: "Title",
    },
    {
      key: "description",
      text: "Description",
    },
    {
      key: "categories.0.name",
      text: "Categories",
    },
    // {
    //   key: "images.0.file",
    //   text: "FILE",
    // },
    // {
    //   key: "isFeatured",
    //   text: "Show in Home",
    // },
    // {
    //   key: "views",
    //   text: "Views Received",
    // },
    {
      key: "isActive",
      text: "Status",
    },
    {
      key: "user.name",
      text: "Added By",
    },
  ];
  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data:any) => {history.push(Edit_Visualstory.replace(":id",data._id))},
    },
    {
      text: "Inactive",
      trigger: (data: any) => {
        updateVisualstory({
          _id: data._id,
          isActive: data.isActive ==="Active" ? false : true,
        }, onSuccessUpdate, onFailure);
      },
      
      conditionalText:(data: any)=> (data.isActive === "Active" ? "Inactive":"Active"),
    },
  ]
  const onCategorySuccess = (res:any)=>{
    const options = res.data.map((category: any) => ({
      label: category.name,
      value: category._id,
    }));
    setCategoryOptions(options);
  }
  const pageChange = (page:any)=>{
      getVisualstoriesList(onSuccess,onFailure,{...listParams,page:page});
      SetCurrentPage(page);
  };
  const search = (query: any) => {
    getVisualstoriesList(onSuccess, onFailure, { ...listParams, ...query });
  };
  return (
      <>
      <main className="app-content" id="app">
          <div className="app-title">
            <div>
                <h1>VisualStories Management</h1>
            </div>
            <Button 
            onClick={()=>history.push(Create_Visualstory)}
            className="btn btn-success"
            >
                Add Visualstory
            </Button>
          </div>
          <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body table-responsive">
              <div className="d-flex justify-content-end">
                  <Button 
                  variant='outline-info'
                  onClick={()=>setShowFilter(!showFilter)}        
                  >
                    <AiFillFilter />
                  </Button>
                </div>
                {showFilter && (
        <Form
          initialValues={{}}
          onSubmit={(values: any) => {
            search(values);
          }}
          render={({ handleChange, resetForm, submitForm }: any) => {
            return (
              <div className="row">
                <div className="col-md-3 p-2">
                  <Input
                    type="text"
                    name="search"
                    // label="TITLE"
                    placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 p-2">
                  <Select
                    isMulti={true}
                    name="categories"
                    id="categories"
                    options={options}
                    placeholder="Select Category"
                  />
                </div>
                <div className="col-md-3 p-2">
                <Input
                    type="date"
                    name="date"
                    // label="TITLE"
                    // placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 p-2">
                  <ButtonGroup>
                    <Button className="btn btn-success" type="submit">
                      Apply
                    </Button>
                    <Button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={() => {
                        resetForm()
                        submitForm()
                      }}
                    >
                      Reset
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );
          }}
        />
      )}
             
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  actionItems={actionItems}
                  formatters={[""]}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page)=>pageChange(page)}
                      // app.use(passport.initialize());
                      page={currentpage}
                    />
                  )}
                ></PaginatedTable>
             </div>
            </div> 
         </div> 
        </div> 
      </main>
    </>
  );
}

export default Managevisualstories;