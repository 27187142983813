import React from 'react';
import Styled from 'styled-components';
import { useField } from 'formik';
import { Label } from './Label';
import { ErrorMessage } from './ErrorMessage';
import { useFormikContext } from 'formik';
import { CSSProperties } from 'react';

export interface TextAreaProps {
  id: string;
  name: string;
  placeholder?: string;
  label?: string;
  className?: string;
  style?: CSSProperties;
  required?: boolean;
  row?: number;
}

const CustomTextArea = Styled.textarea`
   
    &::placeholder{
        color: #919191;
        font-size: 16px;
        font-weight: normal;
    };

    &:focus{
        box-shadow: none;
        border: 1px solid #239F87;
    }
`;

export const TextArea = ({
  id,
  name,
  placeholder,
  label,
  required,
  className,
  style,
  row,
}: TextAreaProps) => {
  const [field, meta] = useField({ name });
  const { submitCount } = useFormikContext();
  const showError = (submitCount > 0 || meta.touched) && meta.error;
  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} required={required} />
      <CustomTextArea
        id={id}
        className={`form-control ${showError && 'is-invalid'}`}
        placeholder={placeholder}
        rows={row}
        {...field}
      />
      <ErrorMessage meta={meta} />
    </div>
  );
};
