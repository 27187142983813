import React from 'react';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Label } from './../Label';
import { TagInputProps } from './input.types';
import { ErrorMessage } from './../ErrorMessage';

export const TagInputField = ({
  id,
  name,
  onChange,
  placeholder,
  value,
  className,
  style,
  label,
  takeSpace,
  required,
}: TagInputProps) => {
  const [field, meta, helpers] = useField({ name });
  const { setValue } = helpers;
  const [inputValue, setInputValue] = useState<string>('');
  const { submitCount } = useFormikContext();
  const showError = (submitCount > 0 || meta.touched) && meta.error;

  const _onChange = (v: any) => {
    setInputValue(v.value);
    onChange && onChange(v);
  };

  const _onKeyDown = (e: any) => {
    const { key } = e;
    const trimmedInput = e.target.value.trim();
    // console.log(field.value);
    if (
      [',', 'Tab'].includes(key) &&
      trimmedInput.length &&
      !field?.value?.includes(trimmedInput)
    ) {
      e.preventDefault();
      if (field.value) {
        setValue([...field.value, trimmedInput]);
      } else {
        setValue([trimmedInput]);
      }
      setInputValue('');
    }
  };

  const handleTagClick = (delTag: string) => {
    const newTags = field.value.filter((tag: string) => tag !== delTag);
    setValue(newTags);
  };

  const input = (
    <input
      type={'text'}
      id={id || name}
      className={`form-control ${showError ? 'is-invalid' : ''}`}
      placeholder={placeholder}
      {...field}
      value={value ? value : inputValue}
      onChange={_onChange}
      onKeyDown={_onKeyDown}
    />
  );

  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      {input}
      <div className="d-flex flex-wrap mt-2">
        {field.value?.length > 0 &&
          field.value.map((tag: string) => (
            <Badge
              bg="secondary"
              className="m-1"
              onClick={() => handleTagClick(tag)}
              style={{ cursor: 'pointer', fontSize: '.8rem' }}
            >
              {tag}
            </Badge>
          ))}
      </div>
      <ErrorMessage meta={meta} />
    </div>
  );
};
