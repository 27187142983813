import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SMALL_PAGE_SIZE } from "../../../constant";
import { Edit_Epaper } from "../../../RouteConstant";
import { getEpaperList, updateEpaper } from "../../../Services/Service";
import { onFailure } from "../../../util/helper";
import { CmsStatus } from "../../../enums";
import Swal from "sweetalert2";
import { Form, Input, PaginatedTable, Paginator } from "../../../Components";
import { ActionInterface } from "../../../Components/BasicTable";
import moment from "moment";
import { Create_Epaper } from "../../../RouteConstant";

const MangageEpaper = () => {
  const [data, setData] = React.useState({ list: [], total: 0 });
  const history = useHistory();
  const [currentPage, setPage] = useState(1);
  const listParams = { populate: "user_f=name" };
  const defaultFormValues = {
    date: "",
  };
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const onSuccess = (data: any) => {
    setData(data.data);
  };

  React.useEffect(() => {
    getEpaperList(onSuccess, onFailure, listParams);
  }, []);
  const search = (query: any) => {
    getEpaperList(onSuccess, onFailure, { ...listParams, ...query });
  };
  const headings = [
    {
      key: "title",
      text: "Title",
    },
    {
      key: "slug",
      text: "Url",
    },
    {
      key: "user.name",
      text: "Author Name",
    },
    {
      key: "status",
      text: "Status",
    },
    {
      key: "createdAt",
      text: "Created Date",
    },
    {
      key: "updatedAt",
      text: "Updated Date",
    },
    {
      key: "publishDate",
      text: "Published Date",
    },
  ];

  const formatters = {
    createdAt: (data: any) => moment(data).format("DD/MM/YYYY HH:SS"),
    updatedAt: (data: any) => moment(data).format("DD/MM/YYYY HH:SS"),
    publishDate: (data: any) => moment(data).format("DD/MM/YYYY HH:SS"),
  };

  const pageChange = (page: any) => {
    getEpaperList(onSuccess, onFailure, { ...listParams, page: page });
    setPage(page);
  };

  const publishSuccess = (res: any) => {
    Swal.fire({
      title: "Epaper Published Successfully",
      icon: "success",
    });
    getEpaperList(onSuccess, onFailure, { ...listParams, page: currentPage });
  };

  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) =>
        history.push(Edit_Epaper.replace(":id", data._id)),
    },
    {
      text: "Publish",
      trigger: (data: any) =>
        updateEpaper(
          {
            _id: data._id,
            status:
              data.status === CmsStatus.Publish
                ? CmsStatus.Draft
                : CmsStatus.Publish,
          },
          publishSuccess,
          onFailure
        ),
      conditionalText: (data: any) =>
        data.status === CmsStatus.Publish ? "Un-Publish" : "Publish",
    },
  ];
  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Epaper Management</h1>
          </div>
          <Button
            onClick={() => history.push(Create_Epaper)}
            className="btn btn-success"
          >
            Add Epaper
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <Form
                  initialValues={initialValues}
                  onSubmit={(values: any) => {
                    // console.log(values);
                    search(values);
                  }}
                  render={({ handleChange, resetForm, submitForm }: any) => {
                    return (
                      <div className="row">
                        <div className="col-md-3 p-2">
                          <Input
                            type="date"
                            name="date"
                            // label="TITLE"
                            // placeholder="Enter Text to search"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-3 p-2">
                          <ButtonGroup>
                            <Button className="btn btn-success" type="submit">
                              Apply
                            </Button>
                            <Button
                              className="btn btn-outline-danger"
                              type="button"
                              onClick={() => {
                                resetForm();
                                submitForm();
                              }}
                            >
                              Reset
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    );
                  }}
                />
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  actionItems={actionItems}
                  formatters={formatters}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page) => pageChange(page)}
                      page={currentPage}
                    />
                  )}
                ></PaginatedTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default MangageEpaper;
