import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { BackButton } from "../../../Components/BackButton";
import { onFailure } from "../../../util/helper";
import {
  createClient,
  getClient,
  updateClient,
} from "../../../Services/Service";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, FileUpload, DateTime } from "../../../Components";

const CreateClient = () => {
  let { id }: any = useParams();

  const [initialValues, setInitialValues] = useState<any>({});

  const onGetSuccess = (res: any) => {
    setInitialValues(res.data);
  };

  useEffect(() => {
    if (id) {
      getClient(id, onGetSuccess, onFailure);
    }
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Client Added Successfully",
      icon: "success",
    });
  };
  return (
    <>
      <main className="app-content" id="app">
        <BackButton />

        <div className="tile">
          <div>
            <div className="app-title">
              {id && <h1>Update Client</h1>}
              {!id && <h1>Add Client</h1>}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  onSubmit={(values: any) => {
                    if (id) {
                      updateClient(values, onSuccess, onFailure);
                    } else {
                      createClient(values, onSuccess, onFailure);
                    }
                  }}
                  render={({ handleChange, values }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="col-md-12 p-2">
                            <Input
                              type="text"
                              name="name"
                              label="Name"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-12 p-2">
                            <Input
                              type="text"
                              name="email"
                              label="EMAIL"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-12 p-2">
                            <Input
                              type="text"
                              name="mobile"
                              label="MOBILE"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-end uvs">
                          <Button className="btn btn-success" type="submit">
                            <AiFillCheckCircle />
                            Submit
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default CreateClient;
