import { useState } from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { BackButton } from "../Components/BackButton";
import { updateUser } from "../Services/Service";
import { PasswordSchema, UserSchema } from "../util/ValidationSchema";
import Swal from "sweetalert2";
import { ErrorBlock } from "../Components/Helper";
import { Form } from "./../Components/Form";
import { Input } from "./../Components/input/input";

const ChangePasswordPage = () => {
  const [error, setError] = useState();

  const [initialValues, setInitialValue] = useState({
    password: "",
    confirmPassword: "",
  });

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Password Updated Successfully",
      icon: "success",
    }).then(()=>{
      window.location.reload();
    });
    setInitialValue({
      password: "",
      confirmPassword: "",
    });
  };

  const userFailure = (error: any) => {
    if (error.status == 400) {
      setError(error.data.message.msg.split(":")[1]);
    }
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="tile">
              <h3 className="text-center">Change Password</h3>
              {error && <ErrorBlock>{error}</ErrorBlock>}

              <Form
                initialValues={initialValues}
                validationSchema={PasswordSchema}
                onSubmit={(values: any) => {
                  updateUser(values, onSuccess, userFailure);
                }}
                render={({ handleChange }: any) => {
                  return (
                    <>
                      <div className="row">
                        <div className="form-group">
                          <div className="col-md-12">
                            <div className="row p-2">
                              <div className="col-md-2">
                                <label htmlFor="password">Password</label>
                              </div>
                              <div className="col-md-10">
                                <Input
                                  type="password"
                                  name="password"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-2">
                                <label htmlFor="confirmPassword">
                                  Confirm Password
                                </label>
                              </div>
                              <div className="col-md-10">
                                <Input
                                  type="password"
                                  name="confirmPassword"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button className="btn btn-primary " type="submit">
                          <AiFillCheckCircle />
                          Update
                        </Button>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default ChangePasswordPage;
