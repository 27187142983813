import { useEffect, useState } from "react";
import { ASSET_URL } from "../constant";
import { getAsset } from "../Services/Service";
import { onFailure } from "./../util/helper";

export const ImageFromAsset = ({ id }: any) => {
  const [image, setImage] = useState<any>();
  const onSuccess = ({ data }: any) => {
    setImage(data);
  };
  useEffect(() => {
    if (id) {
      getAsset(id, onSuccess, onFailure);
    }
  }, [id]);

  return image ? (
    <img src={`${ASSET_URL}/${image.key}`} alt={image.name} className="img-fluid d-block text-truncate" />
  ) : (
    <></>
  );
};
