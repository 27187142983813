import { BackIcon } from "../assets/icons/icons";
import { useHistory } from "react-router-dom";

export const BackButton = () => {
  const history = useHistory();
  return (
    
      <button className="back mb-4" onClick={() => history.goBack()}>
        <span>
          <BackIcon />
        </span>{" "}
        Back
      </button>
    
  );
};
