import { Button } from "react-bootstrap";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { uploadPublic } from "../../../Services/FileUploadService";
import { FieldArray } from "formik";
import {
  createAdvertisement,
  getAdvertisement,
  getClientList,
  updateAdvertisement,
} from "../../../Services/Service";
import { ASSET_URL } from "../../../constant";
import { onFailure } from "../../../util/helper";
import Swal from "sweetalert2";
import { BackButton } from "../../../Components/BackButton";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "../../../Components/Select";
import { getCategoryList } from "../../../Services/Service";
import omit from "lodash/omit";
import {
  Form,
  Input,
  FileUpload,
  DateTime,
  CheckBox,
} from "../../../Components";
import { Ad_Management } from "../../../RouteConstant";

export const CreateAdvertisement = () => {
  let { id }: any = useParams();
  const history = useHistory();
  const [categoryOption, setCategoryOptions] = useState([]);
  const [clientOption, setClientOptions] = useState([]);

  const [initialValues, setInitialValues] = useState<any>({
    images: [
      {
        screenType: "Mobile",
        file: "",
      },
      {
        screenType: "Desktop",
        file: "",
      },
    ],
  });

  const positions = [
    {
      label: "Top",
      value: "Top",
    },
    {
      label: "Bottom",
      value: "Bottom",
    },
    {
      label: "Right Top",
      value: "RightTop",
    },
    {
      label: "Right Bottom",
      value: "RightBottom",
    },
  ];

  const onGetSuccess = (res: any) => {
    const data: any = omit(res.data, [
      "views",
      "clicks",
      "createdAt",
      "updatedAt",
    ]);
    setInitialValues(data);
  };

  useEffect(() => {
    if (id) {
      getAdvertisement(id, onGetSuccess, onFailure);
    }
    getCategoryList(categorySuccess, onFailure);
    getClientList(clientSuccess, onFailure);
  }, []);

  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: `${(category.parent && category.parent != null) ? `${category.parent[0]?.name} |` : ''}  ${category.name}`,
      value: category._id,
    }));
    setCategoryOptions(options);
  };
  const clientSuccess = ({ data }: any) => {
    const options = data.list.map((client: any) => ({
      label: client.name,
      value: client._id,
    }));
    setClientOptions(options);
  };

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Advertisement Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Ad_Management);
    });
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="tile">
          <div>
            <div className="app-title">
              {id && <h1>Update Advertisement</h1>}
              {!id && <h1>Add Advertisement</h1>}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  onSubmit={(values: any) => {
                    let payload = values
                    if (values.images[0]?.file !== "") {
                      if (values.position === "Top") {
                        payload.images = [
                          {
                            screenType: "Mobile",
                            file: values.images[0].file,
                          }, 
                          {
                            screenType: "Desktop",
                            file: values.images[1].file,
                          },
                        ]
                      } else {
                        payload.images = [
                          {
                            screenType: "Mobile",
                            file: values.images[0].file,
                          },
                        ]
                      }
                    }else{
                      payload.images = []
                    }
                    if (values?.videos && values.videos[0]?.file !== ""){
                      payload.videos = values.videos
                    }else{
                     payload.videos = []
                    };
                    if (id) {
                      updateAdvertisement(payload, onSuccess, onFailure);
                    } else {
                      createAdvertisement(payload, onSuccess, onFailure);
                    }
                  }}
                  render={({ handleChange, values }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row ">
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="title"
                                    label="TITLE"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`description`}
                                    label="Description"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`url`}
                                    label="URL"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-4 p-2">
                                  <Select
                                    isMulti={false}
                                    name="client"
                                    id="client"
                                    options={clientOption}
                                    label="Select Client"
                                  />
                                </div>
                                <div className="col-md-4 p-2">
                                  <Select
                                    isMulti={true}
                                    name="categories"
                                    id="categories"
                                    options={categoryOption}
                                    label="Select Cateogries"
                                  />
                                </div>
                                <div className="col-md-4 p-2">
                                  <Input
                                    type="number"
                                    name={`maxImpressionAllowed`}
                                    label="Impression Allowed"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4 p-2">
                                  <DateTime
                                    name={`startDate`}
                                    id={`startDate`}
                                    label="Start Date"
                                    required
                                    minDate={new Date()}
                                    showTimeSelect={true}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                  />
                                </div>
                                <div className="col-md-4 p-2">
                                  <DateTime
                                    name={`endDate`}
                                    id={`endDate`}
                                    label="End Date"
                                    required
                                    minDate={new Date()}
                                    showTimeSelect={true}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                  />
                                </div>
                                <div className="col-md-4 p-2">
                                  <Select
                                    name="position"
                                    id="position"
                                    options={positions}
                                    label="Select Add Position"
                                  />
                                </div>
                              </div>
                              <FieldArray name="images">
                                {({ }) => (
                                  <div className="row ">
                                    <h5>Advertise Images</h5>
                                    <div className="col-md-6 p-2">
                                      <FileUpload
                                        accept={["image/png", "image/jpeg","image/webp"]}
                                        type="single"
                                        maxSize={"3mb"}
                                        name={`images.0.file`}
                                        upload={uploadPublic}
                                        assetUrl={ASSET_URL}
                                        label={`Mobile Image`}
                                        tag={true}
                                      />
                                    </div>
                                    {values.position === "Top" && (
                                      <div className="col-md-6 p-2">
                                        <FileUpload
                                          accept={["image/png", "image/jpeg","image/webp"]}
                                          type="single"
                                          maxSize={"3mb"}
                                          name={`images.1.file`}
                                          upload={uploadPublic}
                                          assetUrl={ASSET_URL}
                                          label={`Desktop Image`}
                                          tag={true}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </FieldArray>
                              <FieldArray name="videos">
                                {({ }) => (
                                  <div className="row ">
                                    <h5>Advertise Videos</h5>
                                    <div className="col-md-12 p-2">
                                      <FileUpload
                                        accept={["video/mp4"]}
                                        type="single"
                                        maxSize={"1gb"}
                                        name={"videos.0.file"}
                                        upload={uploadPublic}
                                        assetUrl={ASSET_URL}
                                        label="Video"
                                      />
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between uvs p-2">
                          <CheckBox
                            name="isFeatured"
                            id="isFeatured"
                            label="Feature in Home page"
                          />
                          <CheckBox
                            name="defaultPage"
                            id="deafultPage"
                            label="Default Page"
                          />
                          <Button className="btn btn-success" type="submit">
                            <AiFillCheckCircle />
                            SUBMIT
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
