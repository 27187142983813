import { HTML, MetaTags } from "./interfaces";

export const HOME = "HOME";
export const ADMIN = "ADMIN";
export const DASHBOARD = "DASHBOARD";
export const SETTINGS = "SETTINGS";
export const PROFILE = "PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const MANAGE_ADMIN = "MANAGE_ADMIN";
export const ADMIN_PAGE = "ADMIN_PAGE";
export const MANAGE_CATEGORY = "MANAGE_CATEGORY";
export const MANAGE_USER = "MANAGE_USER";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const MANAGE_ROLE = "MANAGE_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const PAGE_MANAGEMENT = "PAGE_MANAGEMENT";
export const ARTICLE_MANAGEMENT = "ARTICLE_MANAGEMENT";
export const MENU_MANAGEMENT = "MENU_MANAGEMENT";
export const CREATE_MENU = "CREATE_MENU";
export const EDIT_MENU = "EDIT_MENU";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const CREATE_GALLERY_ARTICLE = "CREATE_GALLERY_ARTICLE";
export const CREATE_VIDEO_ARTICLE = "CREATE_VIDEO_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const EDIT_GALLERY_ARTICLE = "EDIT_GALLERY_ARTICLE";
export const EDIT_VIDEO_ARTICLE = "EDIT_VIDEO_ARTICLE";
export const EDIT_EPAPER = "EDIT_EPAPER";
export const CREATE_EPAPER = "CREATE_EPAPER";
export const MANAGE_EPAPER = "MANAGE_EPAPER";
export const CREATE_AD = "CREATE_AD";
export const EDIT_AD = "EDIT_AD";
export const MANAGE_AD = "MANAGE_AD";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const MANAGE_CLIENT = "MANAGE_CLIENT";
export const CREATE_PAGE = "CREATE_PAGE";
export const HOME_TOP_HEADLINE = "HOME_TOP_HEADLINE";
export const HOME_COVER_STORY = "HOME_COVER_STORY";
export const HOME_TOP_STORY = "HOME_TOP_STORY";
export const HOME_MANAGE_SECTION = "HOME_MANGE_SECTION";
export const EDIT_PAGE = "EDIT_PAGE";
export const MANAGE_VISUALSTORIES ="MANAGE_VISUALSTORIES";
export const CREATE_VISUALSTORY = "CREATE_VISUALSTORY";
export const EDIT_VISUALSTORY = "EDIT_VISUALSTORY";
export const MANAGE_VISUALCATEGORY = "MANAGE_VISUALCATEGORY";
export const CREATE_VISUALCATEGORY = "CREATE_VISUALCATEGORY";
export const MANAGE_LIVEBLOG = "MANAGE_LIVEBLOG";
export const LIVEBLOG_CREATE = "LIVEBLOG_CREATE";
export const LIVEBLOG_EDIT ="LIVEBLOG_EDIT"
let BASE_URL = "https://api.civicmirror.in/api/v1";
  //let BASE_URL = "http://localhost:4444/api/v1";
export const MANAGE_BREAKING_NEWS = "MANAGE_BREAKING_NEWS";
export const SITE_URL = "http://civicmirror.in";
if (process.env.REACT_APP_ENV === "local") {
  BASE_URL = "http://localhost:8888/api/v1";
}
if (process.env.REACT_APP_ENV === "dev") {
  BASE_URL = "http://dev.mirror.lexiconmile.com/api/v1";
}
export const ASSET_URL =
  "https://civicmirror-bucket.s3.ap-south-1.amazonaws.com/full";
export const SMALL_PAGE_SIZE = 10;
export const HTML_FIELDS: HTML = {
  title: "",
  body: "",
};
export const META_TAGS: MetaTags = {
  content: "",
  name: "",
};
export enum PERMISSION_ENUM {
  User = "User",
  Role = "Role",
  Asset = "Asset",
  Category = "Category",
  Cms = "Cms",
  Menu = "Menu",
  Epaper = "Epaper",
  Advertisement = "Advertisement",
  Visualstories = "VisualStories",
  VisualCategory = "VisualCategory",
  Client = "Client",
  BreakingNews = "BreakingNews",
}
export const PERMISSION_MODULE: any = {
  User: "User",
  Role: "Roles and Permissons",
  Asset: "Media",
  Category: "Categories",
  Cms: "Article",
  Menu: "Menu",
  Epaper: "Epaper",
};

export enum PERMISSION_ACCESS_ENUM {
  Read = "Read",
  Write = "Write",
  Edit = "Edit",
  EditOwn = "Edit:own",
  Remove = "Remove",
}

export const PERMISSION_ACTION: string[] = [
  "Read",
  "Write",
  "Edit",
  "Edit:own",
];

export const TEMPLATE_OPTIONS = [
  {
    label: "Cards",
    value: "template-1",
  },
  {
    label: "News Columns",
    value: "template-2",
  },
  {
    label: "Sports Columns",
    value: "template-3",
  },
  {
    label: "Box+List",
    value: "template-4",
  },
  {
    label: "Box+Text",
    value: "template-5",
  },
  {
    label: "Lists",
    value: "template-6",
  },
];

export { BASE_URL };
