import { DefaultProps } from "../util/types";
import { useContext } from "react";
import { AppContext } from "./../App";
import { BackButton } from "./BackButton";

export const PermissionCheckHoc = ({
  resource,
  action,
  children,
}: DefaultProps) => {
  const { state } = useContext<any>(AppContext);

  const permissions = state.user.modelPermissions;

  let isAllowed = false;
  if (
    permissions.hasOwnProperty(resource) &&
    action.some(
      (act) => permissions[resource] && permissions[resource].includes(act)
    )
  ) {
    isAllowed = true;
  } else if (state.user.isSuperAdmin) {
    isAllowed = true;
  }
  if (!resource) {
    isAllowed = true;
  }

  if (isAllowed) {
    return <>{children}</>;
  } else {
    return (
      <>
        <main className="app-content" id="app">
          <BackButton />

          <div className="app-title">
            <h3>
              You do not have permissions to perform this action, please contact
              admin to gain access
            </h3>
          </div>
        </main>
      </>
    );
  }
};

export default PermissionCheckHoc;
