import { useState } from "react";
import { useEffect } from "react";
import { getLayout, updateLayout } from "./../../../Services/Service";
import { onFailure } from "./../../../util/helper";
import { Button, Dropdown } from "react-bootstrap";

import Swal from "sweetalert2";
import {
  Form,
  ImageFromAsset,
  Input,
  Model,
  Select,
} from "../../../Components";
import ArticleSelector from "./ArticleSelector";
import { PageType } from "../../../enums";
import { TEMPLATE_OPTIONS } from "../../../constant";
import { FaEdit } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import cloneDeep from "lodash/cloneDeep";

const ManageSection = () => {
  const [layout, setLayout] = useState<any>({});
  const [sections, setSections] = useState<any>({});
  const [sectionType, setSectionType] = useState<PageType>(PageType.Article);
  const [selectedSection, setSelectedSection] = useState<any>({});
  const [openModel, setOpenModel] = useState(false);
  const [openSettingModel, setOpenSettingModel] = useState(false);
  const [dragObject, setDragObject] = useState({
    index: 0,
    dataItem: {},
  });

  const handleCreateModelOpen = () => {
    setOpenModel(true);
  };
  const handleCreateModelClose = () => {
    setOpenModel(false);
  };

  useEffect(() => {
    getLayout("home", layoutSuccess, onFailure, {
      populate: "body.sections.category,body.sections.articles",
    });
  }, []);

  const layoutSuccess = ({ data }: any) => {
    setLayout(data);

    const sectionView = data.body?.sections.reduce((acc: any, section: any) => {
      return {
        ...acc,
        [section.type === PageType.Article
          ? section.category._id
          : section.type]: section,
      };
    }, {});

    setSections(sectionView);
  };

  const updateSuccess = () => {
    Swal.fire({
      title: "Section Successfully Updated",
      icon: "success",
    });
  };

  const selectSection = ({ selectedArticle, category }: any) => {
    if (sectionType === PageType.Article) {
      if (category) {
        if (category._id in sections) {
          setSections({
            ...sections,
            [category._id]: {
              ...sections[category._id],
              articles: selectedArticle,
            },
          });
        } else {
          setSections({
            ...sections,
            [category._id]: {
              category,
              type: PageType.Article,
              articles: selectedArticle,
            },
          });
        }
      }
    } else if (sectionType === PageType.Video) {
      setSections({
        ...sections,
        Video: {
          articles: selectedArticle,
          type: PageType.Video,
        },
      });
    } else if (sectionType === PageType.Gallery) {
      setSections({
        ...sections,
        Gallery: {
          articles: selectedArticle,
          type: PageType.Gallery,
        },
      });
    }
    setOpenModel(false);
  };

  const handleOnDragOver = (dataItem: any, index: number) => {
    setDragObject({ index: index, dataItem: dataItem });
  };

  const handleOnDragEnd = (dataItem: any, index: number) => {
    const arrSections: any = [];
    const prevData = cloneDeep(sections);
    Object.keys(prevData).map((section: string, index: number) => {
      arrSections.push(prevData[section]);
    });
    arrSections.splice(index, 1);
    arrSections.splice(dragObject?.index, 0, dataItem);
    const newPayload = arrSections.reduce((acc: any, section: any) => {
      return {
        ...acc,
        [section.type === PageType.Article
          ? section.category._id
          : section.type]: section,
      };
    }, {});
    setSections(newPayload);
  };

  const saveData = () => {
    const payload = Object.keys(sections).map(
      (section: any) => sections[section]
    );
    updateLayout(
      { ...layout, body: { ...layout.body, sections: payload } },
      updateSuccess,
      onFailure
    );
  };

  return (
    <main className="app-content">
      <div className="tile">
        <div className="app-title d-flex justify-content-between pt-4">
          <h1>Manage Home Page Sections</h1>

           <div className="d-flex">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Add Section
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      handleCreateModelOpen();
                      setSelectedSection({});
                      setSectionType(PageType.Article);
                    }}
                  >
                    Category
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleCreateModelOpen();
                      setSelectedSection({});
                      setSectionType(PageType.Video);
                    }}
                  >
                    Video
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleCreateModelOpen();
                      setSelectedSection({});
                      setSectionType(PageType.Gallery);
                    }}
                  >
                    Gallery
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => console.log("click")}>
                    Advertisement
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button className="btn btn-dark ml-2" onClick={saveData}>
                Save
              </Button>
            </div>
        </div>


        {sections &&
          Object.keys(sections).map((section: string, index: number) => {
            return (
              <div
                className="mt-3"
                draggable={true}
                onDragOver={() => handleOnDragOver(sections[section], index)}
                onDragEnd={() => handleOnDragEnd(sections[section], index)}
              >
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h4>
                    {sections[section]?.name
                      ? sections[section].name
                      : sections[section].type === PageType.Article
                      ? sections[section].category.name
                      : sections[section].type}
                  </h4>

                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn btn-info mr-1"
                      onClick={() => {
                        setSelectedSection(sections[section]);
                        setSectionType(sections[section].type)
                        setOpenModel(true);
                      }}
                    >
                      <FaEdit />
                    </Button>
                    <Button
                      className="btn btn-secondary mr-1"
                      onClick={() => {
                        setSelectedSection(sections[section]);
                        setOpenSettingModel(true);
                      }}
                    >
                      <BsGearFill />
                    </Button>
                    <Button
                      className="btn btn-danger"
                      onClick={() => {
                        let allSection = sections;
                        delete allSection[section];
                        setSelectedSection(allSection);
                      }}
                    >
                      <AiFillDelete />
                    </Button>
                  </div>
                </div>

                <div className="row">
                  {sections[section].articles.map((article: any) => (
                    <div className="col-md-6 col-lg-4 col-xl-3 mb-3">
                      <div
                        style={{ border: "1px solid #eee", borderRadius: "4px" }}
                        className="p-3"
                      >
                        <div className="image-wrapper">
                          <ImageFromAsset id={article.image} />
                        </div>
                        <h6 className="mt-3">{article.title}</h6>
                      </div>
                    </div>
                  ))}
                </div>

                {/* <div
                  style={{ background: "lightgray", borderRadius: "10px" }}
                  className="p-2"
                >

                  {sections[section].articles.map((article: any) => {
                    return (
                      <div className="row mb-2">
                        <div className="col-md-2">
                          <ImageFromAsset id={article.image} />
                        </div>
                        <div className="col-md-9">
                          <h6>{article.title}</h6>
                        </div>
                      </div>
                    );
                  })}
                </div> */}
              </div>
            );
          })}
        <div className="d-flex justify-content-end mt-2">
          <Button className="btn btn-dark" onClick={saveData}>
            Save
          </Button>
        </div>
      </div>
      {openModel && (
        <Model
          centered={true}
          size="lg"
          title="Manage Section"
          show={openModel}
          handleClose={handleCreateModelClose}
          backdrop="static"
        >
          <ArticleSelector
            onSave={selectSection}
            type={sectionType}
            data={selectedSection?.articles}
            activeCategory={selectedSection?.category}
            pageType={'section'}
          />
        </Model>
      )}
      {openSettingModel && (
        <Model
          centered={true}
          size="sm"
          title="Settings"
          show={openSettingModel}
          handleClose={() => setOpenSettingModel(false)}
          backdrop="static"
        >
          <Form
            initialValues={{
              name: selectedSection?.name || "",
              template: selectedSection?.template,
              color: selectedSection?.color || "",
            }}
            onSubmit={(values: any) => {
              const catId = selectedSection.category._id;
              // console.log({
              //   ...sections,
              //   [catId]: {
              //     ...sections[catId],
              //     ...values,
              //   },
              // });
              setSections({
                ...sections,
                [catId]: {
                  ...sections[catId],
                  ...values,
                },
              });
              setOpenSettingModel(false);
            }}
            render={({ handleChange }: any) => {
              return (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        type="text"
                        name="name"
                        label="NAME"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-12">
                      <Input
                        type="text"
                        name="color"
                        label="COLOR"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-12">
                      <Select
                        options={TEMPLATE_OPTIONS}
                        id="template"
                        name="template"
                        label="Select Template"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button className="btn btn-success" type="submit">
                      OK
                    </Button>
                  </div>
                </>
              );
            }}
          />
        </Model>
      )}
    </main>
  );
};

export default ManageSection;
