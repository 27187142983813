import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { login } from "../Services/Service";
import { Dashboard } from "../RouteConstant";
import { AppContext } from "../App";
import { useContext } from "react";
import { UserRole } from "../enums";
import { AiOutlineKey, AiOutlineUser } from "react-icons/ai";
import { ActionType } from "../Reducer";
import { onFailure } from "./../util/helper";
import { ErrorBlock } from "../Components/Helper";
import { Form } from "./../Components/Form";
import { Input } from "./../Components/input/input";

const LoginPage = () => {
  const { state, dispatch } = useContext<any>(AppContext);
  const [error, setError] = useState("");

  const initialValues = {
    email: "",
    password: "",
  };
  const history = useHistory();

  useEffect(() => {
    const user = state?.user;

    if (!!user) {
      history.push(Dashboard);
    }
  }, [state]);

  const onSuccess = ({ data }: any) => {
    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: data.user,
    });
    window.location.reload();
  };

  const loginFailure = (error: any) => {
    if (error.status == 400) {
      setError(error.data.message.msg.split(":")[1]);
    } else {
      setError("Unable to login at the moment, Please try again");
    }
  };

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="user_card">
            <div className="d-flex justify-content-center">
              <div>
                <h1>Admin Portal</h1>
                <img src="" className="img-fluid" alt="" />
              </div>
            </div>
            {error && <ErrorBlock>{error}</ErrorBlock>}
            <div className="d-flex justify-content-center">
              <Form
                initialValues={initialValues}
                onSubmit={(values: any) =>
                  login(values, onSuccess, loginFailure)
                }
                render={({ handleChange, submitForm }: any) => {
                  return (
                    <>
                      <div className="input-group mb-3">
                        <div
                          className="input-group-append"
                          style={{ zIndex: 99 }}
                        >
                          <span className="input-group-text" id="basic-addon1">
                            <AiOutlineUser />
                          </span>
                        </div>
                        <Input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email address"
                          style={{ marginBottom: 0, marginLeft: "-3px" }}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-group mb-2">
                        <div
                          className="input-group-append"
                          style={{ zIndex: 99 }}
                        >
                          <span className="input-group-text">
                            <AiOutlineKey />
                          </span>
                        </div>
                        <Input
                          type="password"
                          id="password"
                          name="password"
                          style={{ marginBottom: 0, marginLeft: "-3px" }}
                          placeholder="Password"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-3 login_container">
                        <Button type="submit" className="btn login_btn">
                          Login
                        </Button>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
