import React from "react";
import { Paginator } from "./Paginator";
import styled from "styled-components";

export interface DataPageType {
  data: any;
  pageSize: number;
  totalSize: number;
  onChange: (a: number) => void;
}

export interface DataType {
  currentPage: number;
}
export class DataPager extends React.Component<DataPageType, DataType> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentPage: 1,
    };
  }

  getTotalPages = () => {
    return Math.ceil(this.props.totalSize / this.props.pageSize);
  };

  componentDidMount() {
    this.emitDataAtPage(this.state.currentPage);
  }

  handleChange = (page: any) => {
    this.setState({ currentPage: page });
    this.emitDataAtPage(page);
  };

  emitDataAtPage = (page: any) => {
    this.props.onChange(page);
  };

  render() {
    const { currentPage } = this.state;
    return (
      <Paginator
        page={currentPage}
        onPageChange={this.handleChange}
        // totalPages={10}
        totalPages={this.getTotalPages()}
      />
    );
  }
}

export default DataPager;

export const StyledDataPager = styled(DataPager)`
  justify-content: "flex-start";
  margin-left: auto;
`;
