import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { BackButton } from "../Components/BackButton";
import { getUserDetail, updateUser } from "../Services/Service";
import { UserSchema } from "../util/ValidationSchema";
import Swal from "sweetalert2";
import { onFailure } from "../util/helper";
import { ErrorBlock } from "../Components/Helper";
import { Form } from "./../Components/Form";
import { Input } from "./../Components/input/input";

const ProfilePage = () => {
  const [error, setError] = useState();

  const [initialValues, setInitialValue] = useState();

  const onGetSuccess = (res: any) => {
    setInitialValue(res.data);
  };

  useEffect(() => {
    getUserDetail(onGetSuccess, onFailure);
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Profile updated Successfully",
      icon: "success",
    });
  };

  const userFailure = (error: any) => {
    if (error.status == 400) {
      setError(error.data.message.msg.split(":")[1]);
    }
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="tile">
              <h3 className="text-center">Profile</h3>
              {error && <ErrorBlock>{error}</ErrorBlock>}

              <Form
                initialValues={initialValues}
                onSubmit={(values: any) => {
                  updateUser(values, onSuccess, userFailure);
                }}
                render={({ handleChange }: any) => {
                  return (
                    <>
                      <div className="row">
                        <div className="form-group">
                          <div className="col-md-12">
                            <div className="row p-2">
                              <div className="col-md-2 align-self-end">
                                <label htmlFor="email">Email</label>
                              </div>
                              <div className="col-md-10">
                                <Input
                                  name="email"
                                  type="email"
                                  disabled={true}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-2 align-self-end">
                                <label htmlFor="fname">Name</label>
                              </div>
                              <div className="col-md-10">
                                <Input
                                  type="text"
                                  name="name"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-2 align-self-end">
                                <label htmlFor="phone">Phone</label>
                              </div>
                              <div className="col-md-10">
                                <Input
                                  type="tel"
                                  name="phone"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-2 align-self-end">
                                <label htmlFor="addressLine1">
                                  Address Line 1
                                </label>
                              </div>
                              <div className="col-md-10">
                                <Input
                                  type="text"
                                  key="addressLine1"
                                  name="addressLine1"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-2 align-self-end">
                                <label htmlFor="addressLine2">
                                  Address Line 2
                                </label>
                              </div>
                              <div className="col-md-10">
                                <Input
                                  type="text"
                                  key="addressLine2"
                                  name="addressLine2"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-2 align-self-end">
                                <label htmlFor="city">City</label>
                              </div>
                              <div className="col-md-4">
                                <Input
                                  type="text"
                                  key="city"
                                  name="city"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-2 align-self-end">
                                <label htmlFor="pincode">Pincode</label>
                              </div>
                              <div className="col-md-4">
                                <Input
                                  type="text"
                                  key="pincode"
                                  name="pincode"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button className="btn btn-primary " type="submit">
                          <AiFillCheckCircle />
                          Save
                        </Button>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default ProfilePage;
