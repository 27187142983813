import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  Form,
  Input,
  Model,
} from "../../Components";
import { createBreakingNews } from "../../Services/Service";
import Swal from "sweetalert2";
import { onFailure } from "../../util/helper";

interface BreakingNewsInterface {
  show: boolean;
  handleClose: () => void;
  setopenModel: (a: boolean) => void;
}

const AddBreakingNewsModal = ({
  show,
  handleClose,
  setopenModel,
}: BreakingNewsInterface) => {
  const initialValues = {
    title: "",
    url: "",
  };

  const onSuccess = (res: any) => {
    // eslint-disable-next-line no-console

    Swal.fire({
      title: "Breaking News Added Successfully",
      icon: "success",
    });

    setopenModel(false);
  };
const onSubmit = (values:any) =>{
  let payload:any = {};
  if(values.url){
    payload.url = values.url
  }
  payload.title = values.title
  createBreakingNews(payload, onSuccess, onFailure)
}
  return (
    <>
      <Model
        centered={true}
        size="lg"
        title="Add Breaking news"
        show={show}
        handleClose={handleClose}
        backdrop="static"
      >
        <div className="row">
          <div className="col-md-12">
            <Form
              initialValues={initialValues}
              onSubmit={(values: any) =>
                onSubmit(values)
              }
              render={({ handleChange }: any) => {
                return (
                  <>
                    <div className="row">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="row ">
                            <div className="col-md-12">
                              <Input
                                type="text"
                                name="title"
                                label="Breaking News Headline"
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="url"
                                label="URL"
                                onChange={handleChange}
                                // required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center uvs">
                      <Button className="btn btn-info" type="submit">
                        <AiFillCheckCircle />
                        Create Breaking News
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </Model>
    </>
  );
};
export default AddBreakingNewsModal;
