import { useState, useEffect, useRef } from "react";
import { Badge, Button, Dropdown, Tab, Tabs } from "react-bootstrap";
import { CmsStatus } from "../../../enums";
import {
  Create_Article,
  Create_Gallery_Article,
  Create_Video_Article,
} from "../../../RouteConstant";
import {
  getBlogList,
  getCategoryList,
  getCmsNotification,
} from "../../../Services/Service";
import TabData from "./TabData";
import { onFailure } from "./../../../util/helper";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import omit from "lodash/omit";
import { DateTime, Form, Input, Select } from "../../../Components";
import { SITE_URL } from "../../../constant";

const TabTitle = ({ title, count }: any) => {
  return (
    <>
      {title}
      {!!count && count > 0 && (
        <Badge className="ms-1" bg="secondary">
          {count}
        </Badge>
      )}
    </>
  );
};
export const BlogManagementPage = () => {
  const [key, setKey] = useState<string>("all");
  const [categoryOption, setCategoryOptions] = useState([]);
  const [notification, setNotifications] = useState({});
  const [transactionData, setTransactionData] = useState({
    list: [],
    total: 0,
  });
  let listParams: any = {
    populate: "categories_f=name slug categories:parent_f=slug, user_f=name",
  };
  const csvLink: any = useRef();
  const onSuccess = (data: any) => {
    setNotifications(data.data);
  };
  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: category.name,
      value: category._id,
    }));
    setCategoryOptions(options);
  };
  const onSuccessDownload = (data: any) => {
    const list = data.data.list.map((i: any) => {
      const childSlug = i.categories.map((category: any) => {
        return category.slug;
      });
      let categorySlug = "";
      i.categories.forEach(function (category: any) {
        if (category.parent != null) {
          category.parent.forEach(function (child: any) {
            categorySlug = child.slug;
          });
        }
      });
      const url = `${SITE_URL}${
        categorySlug != "" ? `/${categorySlug}` : ""
      }/${childSlug}/${i.slug}/${i.cid}`;
      const article = omit(i, [
        "body",
        "audit",
        "cid",
        "commentAllowed",
        "description",
        "gallery",
        "image",
        "inpress",
        "isSponsored",
        "seo",
        "showTitle",
        "tags",
        "videos",
        "views",
        "user",
        "slug",
        "categories",
        "old_url",
        "migrated",
        "publishedBy",
        "imageTitle",
      ]);
      article.user = i.user?.name;
      article.category = i.categories[0]?.name;
      return { ...article, url };
    });
    data.data.list = list;
    setTransactionData(data.data);
    csvLink.current.link.click();
  };

  const getTransactionData = async (values: any) => {
    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    getBlogList(onSuccessDownload, onFailure, {
      ...listParams,
      ...values,
      pageSize: 5000,
    });
  };

  useEffect(() => {
    getCategoryList(categorySuccess, onFailure);
    getCmsNotification(onSuccess, onFailure);
  }, []);

  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Article Management</h1>
          </div>
          <Form
            initialValues={{ startDate: "", endDate: "" ,search:""}}
            onSubmit={(values: any) => {
              getTransactionData(values);
            }}
            render={({handleChange}: any) => {
              return (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <DateTime
                        name={`startDate`}
                        id={`startDate`}
                        label="Start Date"
                        required
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date()}
                      />
                    </div>
                    <div className="col-md-3">
                      <DateTime
                        name={`endDate`}
                        id={`endDate`}
                        label="End Date"
                        required
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date()}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        type="text"
                        name={`search`}
                        label="Keyword"
                        placeholder="Enter Text to search"
                        onChange={handleChange}
                        // required
                      />
                    </div>
                    <div className="col-md-3">
                      <Select
                        isMulti={true}
                        name="categories"
                        id="categories"
                        options={categoryOption}
                        placeholder="Select Category"
                        label="Category"
                      />
                    </div>
                    <div className="col-md-2 pt-4">
                      <Button className="btn btn-primary " type="submit">
                        {/* <AiFillCheckCircle /> */}
                        Export CSV
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          />
          <CSVLink
            data={transactionData.list}
            filename="CMS.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Create
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={Create_Article}>
                Article
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={Create_Video_Article}>
                Video
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={Create_Gallery_Article}>
                Gallery
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Button
            onClick={() => history.push(Create_Article)}
            className="btn btn-success"
          >
            Add Article
          </Button> */}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k: any) => setKey(k)}
                  className="mb-3"
                  mountOnEnter={true}
                  unmountOnExit={true}
                >
                  <Tab eventKey="all" title="All">
                    <TabData setNotifications={setNotifications} />
                  </Tab>
                  <Tab eventKey="draft" title="Draft">
                    <TabData
                      status={CmsStatus.Draft}
                      setNotifications={setNotifications}
                    />
                  </Tab>

                  {/* <Tab eventKey="approved" title="Approved">
                    <TabData
                      status={CmsStatus.Approved}
                      setNotifications={setNotifications}
                    />
                  </Tab> */}
                  <Tab eventKey="Published" title="Published">
                    <TabData
                      status={CmsStatus.Publish}
                      setNotifications={setNotifications}
                    />
                  </Tab>
                  {/* <Tab
                    eventKey="needReview"
                    title={
                      <TabTitle
                        title="Need Review"
                        count={get(notification, "Need Review")}
                      />
                    }
                  >
                    <TabData
                      status={CmsStatus.NeedReview}
                      setNotifications={setNotifications}
                    />
                  </Tab> */}
                  {/* <Tab eventKey="rejected" title="Rejected">
                    <TabData
                      status={CmsStatus.Rejected}
                      setNotifications={setNotifications}
                    />
                  </Tab> */}
                  <Tab eventKey="retracted" title="Retract">
                    <TabData
                      status={CmsStatus.Retract}
                      setNotifications={setNotifications}
                    />
                  </Tab>

                  <Tab eventKey="scheduled" title="Scheduled">
                    <TabData
                      status={CmsStatus.Scheduled}
                      setNotifications={setNotifications}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
// export default BlogManagementPage;
