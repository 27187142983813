import { useContext } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { AppContext } from "./../App";
import { ActionType } from "../Reducer";
import styled from "styled-components";
import { AiOutlineMenu } from "react-icons/ai";
import { Change_Password, Profile } from "./../RouteConstant";
import { Select } from "./Select";
import LOGO from "../assets/civic-mirror-logo.png";

const StyledSelect = styled(Select)`
  min-width: 200px;
  margin-top: 5px;
  margin-right: 20px;
  @media (max-width: 460px) {
    min-width: 130px;
  }
`;

const ToggleButton = styled(Button)`
  border: none;
  border-radius: 0;
  width: 50px;
  min-width: 50px;
  padding: 0;
  background-color: transparent;
  font-size: 1.5rem;
`;
export const Header = ({ setCollapsed, collapsed }: any) => {
  const { dispatch } = useContext<any>(AppContext);

  const logout = () => {
    dispatch({
      type: ActionType.LOGOUT,
      payload: "",
    });
  };

  return (
    <>
      <header className="app-header">
        <div className="d-flex justify-space-between">
          {!collapsed ?<Link className="app-header__logo" to="/admin">
            <img src={LOGO} height="24" alt="" />
          </Link> : ''}

          <ToggleButton onClick={() => setCollapsed(!collapsed)}>
            <AiOutlineMenu />
          </ToggleButton>

          {collapsed ?<Link className="app-header__logo" to="/admin">
            <img src={LOGO} height="24" alt="" />
          </Link> : ''}
        </div>
        <ul className="app-nav">
          <li>
            <Dropdown style={{ height: "100%" }}>
              <Dropdown.Toggle className="admin-dropdown">
                <FaUser className="text-center" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={Profile}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={Change_Password}>
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </header>
    </>
  );
};
