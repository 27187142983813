import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { Form, Input, Model } from "../../Components";
import { updateBreakingNews } from "../../Services/Service";
import { onFailure } from "../../util/helper";
import Swal from "sweetalert2";

interface BreakingNewsInterface {
  show: boolean;
  handleClose: () => void;
  editValue: any;
  setopenModel: (a: boolean) => void;
}

const EditBreakingNewsPage = ({
  show,
  handleClose,
  editValue,
  setopenModel,
}: BreakingNewsInterface) => {
  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Breaking news Updated Successfully",
      icon: "success",
    });

    setopenModel(false);
  };
  return (
    <>
      <Model
        centered={true}
        title="Edit Category"
        size="lg"
        show={show}
        backdrop="static"
        handleClose={handleClose}
      >
        <div className="row">
          <div className="col-md-12">
            <Form
              initialValues={editValue}
              onSubmit={(values: any) => {
                updateBreakingNews(values, onSuccess, onFailure);
              }}
              render={({ handleChange }: any) => {
                return (
                  <>
                    <div className="row">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="row ">
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="title"
                                label="Breaking News Headline"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="url"
                                label="URL"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center uvs">
                      <Button className="btn btn-info" type="submit">
                        <AiFillCheckCircle />
                        Update
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </Model>
    </>
  );
};
export default EditBreakingNewsPage;
