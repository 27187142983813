import { useState } from "react";
import { useEffect } from "react";
import { getLayout, updateLayout } from "./../../../Services/Service";
import { onFailure } from "./../../../util/helper";
import Swal from "sweetalert2";
import ArticleSelector from "./ArticleSelector";

const TopHeadline = () => {
  const [layout, setLayout] = useState<any>({});

  useEffect(() => {
    getLayout("home", layoutSuccess, onFailure, {
      populate: "body.headlines",
    });
  }, []);

  const layoutSuccess = ({ data }: any) => {
    setLayout(data);
  };

  const updateSuccess = () => {
    Swal.fire({
      title: "Headlines Successfully Updated",
      icon: "success",
    });
  };
  const saveData = ({ selectedArticle }: any) => {
    const payload = selectedArticle.map((article: any) => article._id);
    updateLayout(
      { ...layout, body: { ...layout.body, headlines: payload } },
      updateSuccess,
      onFailure
    );
  };
  return (
    <main className="app-content">
      <div className="tile">
        <div className="app-title">
          <h1>Manage Top Headlines</h1>
        </div>
        <ArticleSelector
          onSave={saveData}
          // type={PageType.Article}
          data={layout?.body?.headlines}
          pageType="top_headline"
        />
      </div>
    </main>
  );
};

export default TopHeadline;
