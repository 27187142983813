import { Field, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Form, Input } from "../../Components";
import { RichTextEditor } from "../../Components/rich-text-editor/rich-text-editor";
import { ASSET_URL } from "../../constant";
import { uploadPublic } from "../../Services/FileUploadService";
import {
  getLiveCommentry,
  updateLiveBlogComment,
} from "../../Services/Service";
import { onFailure } from "../../util/helper";

const EditArticleLiveCommentry = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({
    article: "",
    commentTitle: "",
    description: "",
    isShow: true,
  });
  useEffect(() => {
    if (id) {
      const params = {
        populate: "article",
      };
      getLiveCommentry(id, onGetSuccess, onFailure, params);
    }
  }, [id]);
  const onGetSuccess = (res: any) => {
    setInitialValues(res);
  };
  const onSuccess = (Res: any) => {
    Swal.fire({
      title: "LiveComment updated Successfully",
      icon: "success",
    }).then(() => {
      history.push(`/liveblog/create/${Res.article._id}`);
    });
  };
  return (
    <>
      <main className="app-content">
        <div className="title">
          <h3>Edit Live Article Commentry</h3>
        </div>
        <div className="row">
          <div className="col-md12">
            <Form
              onSubmit={(values: any) => {
                let payload: any = {};
                payload._id = id;
                payload.article = values.article;
                payload.isShow = values.isShow;
                payload.commentTitle = values.commentTitle;
                payload.description = values.description;
                if (payload._id) {
                  updateLiveBlogComment(payload, onSuccess, onFailure);
                }
              }}
              initialValues={initialValues}
              render={({ handleChange, values }: any) => {
                return (
                  <>
                    <div className="row">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 p-2">
                              <Input
                                type="text"
                                name={`commentTitle`}
                                label="Title"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-12 p-2">
                              <Field
                                name={`description`}
                                render={({
                                  form,
                                  field,
                                }: FieldProps<number | string>) => {
                                  return (
                                    <RichTextEditor
                                      id={`description`}
                                      name={`description`}
                                      label="Content"
                                      required
                                      maxHeight={600}
                                      uploadFile={uploadPublic}
                                      getAssetUrl={ASSET_URL}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center uvs p-2">
                      <Button
                        className="btn btn-success mr-2"
                        type="submit"
                        disabled={false}
                      >
                        <AiFillCheckCircle />
                        Save
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default EditArticleLiveCommentry;
