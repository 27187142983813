import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Manage_Role } from "../../RouteConstant";
import { createRole, getRole } from "../../Services/Service";
import { RoleSchema } from "../../util/ValidationSchema";
import Swal from "sweetalert2";
import { onFailure } from "../../util/helper";
import {
  ErrorBlock,
  BackButton,
  Form,
  Input,
  Select,
  CheckBox,
} from "../../Components";
import { useParams } from "react-router-dom";
import { updateRole } from "./../../Services/Service";
import { PERMISSION_ACTION, PERMISSION_MODULE } from "../../constant";
import omit from "lodash/omit";

const CreateRolePage = () => {
  let { id }: any = useParams();
  const [error, setError] = useState();

  const [initialValues, setInitialValue] = useState({});
  const [disableForm, setDisableForm] = useState(true);
  const history = useHistory();

  const onGetSuccess = ({ data }: any) => {
    const payload: any = {
      name: data.name,
      description: data.description,
    };
    const modelPerm = data.modelPermissions;

    Object.keys(modelPerm).forEach((module: string) => {
      modelPerm[module].forEach((action: string) => {
        payload[`${module};${action}`] = true;
      });
    });
    if (data.isEditable) {
      setDisableForm(false);
    }
    setInitialValue(payload);
  };

  useEffect(() => {
    if (id) {
      getRole(id, onGetSuccess, onFailure);
    } else {
      setDisableForm(false);
    }
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Role Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Manage_Role);
    });
  };

  const RoleFailure = (error: any) => {
    if (error.status == 400) {
      setError(error.data.message.msg.split(":")[1]);
    }
  };

  const handleSubmit = (values: any) => {
    const permissionObj = omit(values, ["name", "description"]);
    let permissions: any = {};
    Object.keys(permissionObj).forEach((perm: string) => {
      const [module, access] = perm.split(";");
      if (permissionObj[perm]) {
        if (permissions.hasOwnProperty(module)) {
          permissions[module].push(access);
        } else {
          permissions[module] = [access];
        }
      }
    });
    const payload: any = {
      name: values.name,
      description: values.description,
      modelPermissions: permissions,
    };
    // console.log("permissions", permissions);
    if (id) {
      payload["_id"] = id;
      updateRole(payload, onSuccess, RoleFailure);
    } else {
      createRole(payload, onSuccess, RoleFailure);
    }
  };
  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="tile">
              <div className="w-100">
                <h3 className="text-center">
                  {id ? "Edit Role" : "Create Role"}
                </h3>
                {error && <ErrorBlock>{error}</ErrorBlock>}

                <Form
                  initialValues={initialValues}
                  validationSchema={RoleSchema}
                  onSubmit={handleSubmit}
                  render={({ handleChange }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="email">Name</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    disabled={disableForm}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="fname">Description</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="text"
                                    name="description"
                                    onChange={handleChange}
                                    disabled={disableForm}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-12">
                                  <h5 className="text-secondary">
                                    Select Roles Permissions
                                  </h5>
                                </div>
                                <div className="col-md-12">
                                  {Object.keys(PERMISSION_MODULE).map(
                                    (module: string) => {
                                      return (
                                        <div
                                          className="d-flex text-secondary list-group-item"
                                          // style={{ background: "aquamarine" }}
                                        >
                                          <div className="col-md-4">
                                            <label htmlFor="fname">
                                              {PERMISSION_MODULE[module]}
                                            </label>
                                          </div>
                                          <div className="col-md-8">
                                            <div className="row justify-content-center">
                                              {PERMISSION_ACTION.map(
                                                (action) => (
                                                  <div className="col-auto">
                                                    <CheckBox
                                                      id={`${module}_${action}`}
                                                      name={`${module};${action}`}
                                                      label={action}
                                                      onChange={handleChange}
                                                      disabled={disableForm}
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-primary "
                            type="submit"
                            disabled={disableForm}
                          >
                            <AiFillCheckCircle />
                            Save
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default CreateRolePage;
