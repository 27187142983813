import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SMALL_PAGE_SIZE } from "../../../constant";
import { CmsStatus } from "../../../enums";
import { Create_Menu, Edit_Menu } from "../../../RouteConstant";
import {
  addMenu,
  getMenuList,
  updateCms,
  updateMenu,
} from "../../../Services/Service";
import { onFailure } from "./../../../util/helper";
import Swal from "sweetalert2";
import { PaginatedTable, Paginator } from "./../../../Components";
import { ActionInterface } from "./../../../Components/BasicTable";

const MenuManagementPage = () => {
  const [data, setData] = React.useState({ list: [], total: 0 });
  const history = useHistory();
  const [currentPage, setPage] = useState(1);
  const listParams = { populate: "categories_f=name" };
  const onSuccess = (data: any) => {
    setData(data.data);
  };

  React.useEffect(() => {
    getMenuList(onSuccess, onFailure, listParams);
  }, []);

  const headings = [
    {
      key: "name",
      text: "TITLE",
    },
    {
      key: "active",
      text: "Active",
    },
  ];

  const formatters = {
    active: (data: any) => (data ? "ACTIVE" : "IN-ACTIVE"),
  };

  const pageChange = (page: any) => {
    getMenuList(onSuccess, onFailure, { page: page, ...listParams });
    setPage(page);
  };

  const publishSuccess = (res: any) => {
    Swal.fire({
      title: "Menu Status Updated Successfully",
      icon: "success",
    });
    getMenuList(onSuccess, onFailure, { page: currentPage, ...listParams });
  };

  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => history.push(Edit_Menu.replace(":id", data._id)),
    },
    {
      text: "Activate",
      trigger: (data: any) =>
        updateMenu(
          {
            _id: data._id,
            active: data.active ? false : true,
          },
          publishSuccess,
          onFailure
        ),
      conditionalText: (data: any) =>
        data.active ? "De-Activate" : "Activate",
    },
  ];
  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Menu Management</h1>
          </div>
          <Button
            onClick={() => history.push(Create_Menu)}
            className="btn btn-success"
          >
            Add Menu
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  actionItems={actionItems}
                  formatters={formatters}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page) => pageChange(page)}
                      page={currentPage}
                    />
                  )}
                ></PaginatedTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default MenuManagementPage;
