import { Button } from 'react-bootstrap';
import { BackButton, FileUpload, Form, Input, Select } from '../../Components';
import { FieldArray } from 'formik';
import { ASSET_URL } from '../../constant';
import { uploadPublic } from '../../Services/FileUploadService';
import { AiFillCheckCircle, AiOutlinePlus } from 'react-icons/ai';
import { createVisualstories, getVisualstory, getUserList, getPopulatedCategoryList, updateVisualstory } from '../../Services/Service';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { onFailure } from '../../util/helper';
import Swal from 'sweetalert2';
import { VisualstoriesManagement } from '../../RouteConstant';
import { MdDelete } from 'react-icons/md';

const Createvisualstories = () => {


  let { id }: any = useParams();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);

  const [initialValues, setInitialValues] = useState<any>({
    title: "",
    description: "",
    images: [
      {
        caption: "",
        file: ""
      }
    ],
    videos: [
      {
        caption: "",
        file: ""
      }
    ]
  })

  const onGetSuccess = (res: any) => {
    setInitialValues(res.data);
  }

  useEffect(() => {
    if (id) {
      getVisualstory(id, onGetSuccess, onFailure);
    }
    getPopulatedCategoryList(categorySuccess, onFailure);
    getUserList(onUserSucces, onFailure, { all: true })

  }, [])
  const categorySuccess = (res: any) => {
    const categories = res.data.map((category: any) => ({
      label: `${(category.parent && category.parent != null) ? `${category.parent[0]?.name} |` : ''}  ${category.name}`,
      value: category._id,
    }));
    setCategories(categories);
  };
  const onUserSucces = (res: any) => {
    const options = res.data.list.map((user: any) => ({
      label: user.name,
      value: user._id,
    }));
    setUsers(options)
  }

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "VisualStory Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(VisualstoriesManagement);
    });
  };
  const onUpdateSuccess = (res: any) => {
    Swal.fire({
      title: "VisualStory Updated Successfully",
      icon: "success",
    }).then(() => {
      history.push(VisualstoriesManagement);
    });
  };

  const onSubmit = (values:any) =>{
    let payload:any = {};
    if (id) {
      payload._id = id;
    }
    if(values.description && values.description != ""){
      payload.description = values.description
    }
    if(values.images[0]?.file != ''){
     payload.images = values.images
    }else{
      payload.images =[]
    }
    if(values.videos[0]?.file != ''){
      payload.videos = values.videos
    }else{
      payload.videos = []
    }
    payload.seo = values.seo
    payload.categories = values.categories
    payload.title = values.title
    payload.user = values.user
    payload.coverimage = values.coverimage
    if (id) {
      updateVisualstory(payload, onUpdateSuccess, onFailure);
    }else{
      createVisualstories(payload, onSuccess, onFailure)
    }
  }

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div>
          <div className='app-title'>
            {id && <h1>Update Visualstory</h1>}
            {!id && <h1>Add Visualstory</h1>}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                render={({ handleChange, values }: any) => {
                  return (
                    <>
                      <div className="row">
                        <div className="form-group">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12 p-2">
                                <Input
                                  type='text'
                                  name='title'
                                  label='Title'
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-12 p-2">
                                <Input
                                  type='text'
                                  name='description'
                                  label='Description'
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`canonicalUrl`}
                                    label="Canonical Url"
                                    onChange={handleChange}
                                    // required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.title"
                                    label="SEO TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.description"
                                    label="SEO DESCRIPTION"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    name="seo.metaTags[0].content"
                                    id="seo.metaTags[0].content"
                                    label="Keywords"
                                    maxLength="500"
                                    onChange={handleChange}
                                  />
                                  {/* <small>500 character limit</small> */}
                                </div>
                              <FieldArray name="coverimage">
                                {(push) => (
                                  <div className="col-md-12 p-2">
                                    <FileUpload
                                      accept={[
                                        "image/png",
                                        "image/jpeg",
                                        "image/webp"
                                      ]}
                                      type="single"
                                      maxSize={"3mb"}
                                      name={`coverimage`}
                                      upload={uploadPublic}
                                      assetUrl={ASSET_URL}
                                      label="CoverIMAGE"
                                    />
                                  </div>
                                )}
                              </FieldArray>
                              <div className="col-md-6 p-2">
                                <Select
                                  name="user"
                                  id="user"
                                  options={users}
                                  label="Select Author"
                                  required
                                />
                              </div>
                              <div className="col-md-6 p-2">
                                <Select
                                  isMulti={true}
                                  name="categories"
                                  id="categories"
                                  options={categories}
                                  label="Select Category"
                                  required
                                />
                              </div>

                            </div>

                            <FieldArray name="images">
                              {({ remove, push }) => (
                                <div className="row ">
                                  {values?.images?.length > 0 &&
                                    values.images.map(
                                      (value: any, index: number) => (
                                        <div
                                          key={index}
                                          className="page-section mt-2 position-relative"
                                        >
                                          {/* <div className="col-md-12 p-2">
                                            <Input
                                              type="text"
                                              name={`images.${index}.url`}
                                              label="IMAGE URL"
                                              onChange={handleChange}
                                            />
                                          </div> */}

                                          <div className="col-md-12 p-2">
                                            <FileUpload
                                              accept={[
                                                "image/png",
                                                "image/jpeg",
                                                "image/webp"
                                              ]}
                                              type="single"
                                              maxSize={"1gb"}
                                              name={`images.${index}.file`}
                                              upload={uploadPublic}
                                              assetUrl={ASSET_URL}
                                              label="UPLOAD IMAGE"
                                            />
                                          </div>

                                          <div className="col-md-12 p-2">
                                            <Input
                                              type="text"
                                              name={`images.${index}.caption`}
                                              label="CAPTION"
                                              onChange={handleChange}
                                            />
                                          </div>

                                          {index > 0 && (
                                            <div className="icon-button text-danger">
                                              <MdDelete
                                                onClick={() => remove(index)}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )}
                                  <div className="d-flex justify-content-end uvs p-2">
                                    <Button
                                      size="lg"
                                      variant="success"
                                      onClick={() => push({})}
                                    >
                                      <AiOutlinePlus />
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                            <FieldArray name="videos">
                              {({ remove, push }) => (
                                <div className="row ">
                                  {values?.videos?.length > 0 &&
                                    values.videos.map(
                                      (value: any, index: number) => (
                                        <div
                                          key={index}
                                          className="page-section mt-2 position-relative"
                                        >
                                          {/* <div className="col-md-12 p-2">
                                            <Input
                                              type="text"
                                              name={`videos.${index}.url`}
                                              label="YOUTUBE VIDEO URL"
                                              onChange={handleChange}
                                            />
                                          </div> */}

                                          <div className="col-md-12 p-2">
                                            <FileUpload
                                              accept={["video/mp4"]}
                                              type="single"
                                              maxSize={"1gb"}
                                              name={`videos.${index}.file`}
                                              upload={uploadPublic}
                                              assetUrl={ASSET_URL}
                                              label="UPLOAD VIDEO"
                                            />
                                          </div>
                                          <div className="col-md-12 p-2">
                                            <Input
                                              type="text"
                                              name={`videos.${index}.caption`}
                                              label="CAPTION"
                                              onChange={handleChange}
                                            />
                                          </div>
                                          {index > 0 && (
                                            <div className="icon-button text-danger">
                                              <MdDelete
                                                onClick={() => remove(index)}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )}
                                  <div className="d-flex justify-content-end uvs p-2">
                                    <Button
                                      size="lg"
                                      variant="success"
                                      onClick={() => push({})}
                                      title="Add Videos"
                                    >
                                      <AiOutlinePlus />
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                          </div>

                        </div>
                      </div>
                      <div className="d-flex justify-content-between uvs p-2">
                        <Button className="btn btn-success" type="submit">
                          <AiFillCheckCircle />
                          SUBMIT
                        </Button>
                      </div>
                    </>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Createvisualstories;