import { Button } from "react-bootstrap";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { uploadPublic } from "../../../Services/FileUploadService";
import { Field, FieldArray, FieldProps } from "formik";
import {
  createCms,
  getCms,
  getUserList,
  updateCms,
} from "../../../Services/Service";
import { HTML, MetaTags } from "../../../interfaces";
import { ASSET_URL, HTML_FIELDS, SITE_URL } from "../../../constant";
import { onFailure } from "../../../util/helper";
import Swal from "sweetalert2";
import { BackButton } from "../../../Components/BackButton";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "../../../Components/Select";
import { getPopulatedCategoryList } from "../../../Services/Service";
import omit from "lodash/omit";
import {
  Form,
  Input,
  FileUpload,
  CheckBox,
  DateTime,
} from "../../../Components";
import { Article_Management } from "../../../RouteConstant";
import { RichTextEditor } from "../../../Components/rich-text-editor/rich-text-editor";
export interface ArticleFormValues {
  title: string;
  seo?: {
    title?: string;
    description?: string;
    metaTags?: MetaTags[];
  };
  body: HTML[];
  publishDate: Date;
}

export const CreateArticle = () => {
  let { id }: any = useParams();
  const history = useHistory();
  const [categoryOption, setCategoryOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState<ArticleFormValues>({
    title: "",
    seo: {
      title: "",
      description: "",
    },
    body: [HTML_FIELDS],
    publishDate: new Date(),
  });

  const onGetSuccess = (res: any) => {

    const data: any = omit(res.data, [
      "views",
      "createdAt",
      "updatedAt",
      "slug",
    ]);
    data.categories = res.data.categories[0]
    setInitialValues(data);
  };

  useEffect(() => {
    if (id) {
      const params = {
        populate: "image",
      };
      getCms(id, onGetSuccess, onFailure, params);
    }
    getPopulatedCategoryList(categorySuccess, onFailure);
    getUserList(userSuccess, onFailure, { all: true });
  }, []);
  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: `${
        category.parent && category.parent != null && category.parent.length > 0
          ? `${category.parent[0]?.name} |`
          : ""
      }  ${category.name}`,
      value: category._id,
    }));
    setCategoryOptions(options);
  };
  const userSuccess = (data: any) => {
    const options = data.data.list.map((user: any) => ({
      label: user.name,
      value: user._id,
    }));
    setUsers(options);
  };

  const onSuccess = (res: any) => {
    Swal.fire({
      title: id ? "Article Updated Successfully" : "Article Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Article_Management);
    });
  };

  const onCMSFailure = (err:any)=>{
    setIsSubmitted(false)
    Swal.fire({
      title: err.data.message.msg,
      icon: "error",
    })
  }
  const onSubmit = (values: any, status: any, isPreview = false) => {
    let payload: any = {};
    setIsSubmitted(true);
    if (id) {
      payload._id = id;
    }
    if (values?._id) {
      payload._id = values._id;
    }
    payload.body = values.body;
    payload.image = values.image;
    payload.categories = [values.categories];
    payload.description = values.description;
    payload.publishDate = values.publishDate;
    payload.seo = values.seo;
    payload.title = values.title;
    payload.englishTitle = values.englishTitle;
    payload.user = values.user
    payload.status = status
    if(values.imageTitle){
      payload.imageTitle = values.imageTitle
    }
    payload.user = values.user;
    if(values.editedBy){
      payload.editedBy = values.editedBy;
    }
    payload.status = status;
    payload.inpress = values.inpress;
    payload.isLiveBlog = values.isLiveBlog;
    payload.isSponsored = values.isSponsored;
    payload.isMarkUnfollow = values.isMarkUnfollow;
    
    if (values.canonicalUrl) {
      payload.canonicalUrl = values.canonicalUrl;
    }
    if (values.imageTitle) {
      payload.imageTitle = values.imageTitle;
    }
    let callback: any = onSuccess;
    if (isPreview && payload?._id) {
      callback = (res: any) => onShowPreviewUpdate(res, payload);
    } else if (isPreview) {
      callback = onShowPreviewCreate;
    }

    if (payload?._id) {
      updateCms(payload, callback, onCMSFailure);
    } else {
      createCms(payload, callback, onCMSFailure);
    }
  };
  const onShowPreviewCreate = (res: any) => {
    if (res.data._id) {
      setInitialValues(res.data);
    }
    window.open(`${SITE_URL}/preview/${res.data._id}`, "_blank");
  };
  const onShowPreviewUpdate = (res: any, payload: any) => {
    window.open(`${SITE_URL}/preview/${payload._id}`, "_blank");
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="tile">
          <div>
            <div className="app-title">
              {id && <h1>Update Article</h1>}
              {!id && <h1>Add Article</h1>}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  render={({ handleChange, values }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row ">
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="title"
                                    label="TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="englishTitle"
                                    label="Englis Title"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Select
                                    // isMulti={true}
                                    name="categories"
                                    id="categories"
                                    options={categoryOption}
                                    label="Select Categories"
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Select
                                    name="user"
                                    id="user"
                                    options={users}
                                    label="Select Author"
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Select
                                    name="editedBy"
                                    id="editedBy"
                                    options={users}
                                    label="Select Editor"
                                    // required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`description`}
                                    label="Short Description"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`canonicalUrl`}
                                    label="Canonical Url"
                                    onChange={handleChange}
                                    // required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.title"
                                    label="SEO TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.description"
                                    label="SEO DESCRIPTION"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    name="seo.metaTags[0].content"
                                    id="seo.metaTags[0].content"
                                    label="Keywords"
                                    maxLength="500"
                                    onChange={handleChange}
                                  />
                                  {/* <small>500 character limit</small> */}
                                </div>
                              </div>
                              <FieldArray name="body">
                                {({ remove, push }) => (
                                  <div className="row ">
                                    <h5>SECTION</h5>
                                    {values?.body?.length > 0 &&
                                      values.body.map(
                                        (value: any, index: number) => (
                                          <div
                                            key={index}
                                            className="page-section mt-2 position-relative"
                                          >
                                            <div className="col-md-12 p-2">
                                              <Input
                                                type="text"
                                                name={`body.${index}.title`}
                                                label="Sub-Headline"
                                                onChange={handleChange}
                                              />
                                            </div>
                                            <div className="col-md-12 p-2">
                                              {/* <Label label="Content" /> */}
                                              <Field
                                                name={`body.${index}.body`}
                                                render={({
                                                  form,
                                                  field,
                                                }: FieldProps<
                                                  number | string
                                                >) => {
                                                  return (
                                                    <RichTextEditor
                                                      id={`body.${index}.body`}
                                                      name={`body.${index}.body`}
                                                      label="Content"
                                                      required
                                                      maxHeight={600}
                                                      uploadFile={uploadPublic}
                                                      getAssetUrl={ASSET_URL}
                                                    />
                                                  );
                                                }}
                                              />
                                            </div>
                                            {index > 0 && (
                                              <div className="icon-button text-danger">
                                                <MdDelete
                                                  onClick={() => remove(index)}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                    <div className="d-flex justify-content-end uvs p-2">
                                      <Button
                                        size="lg"
                                        variant="success"
                                        onClick={() => push({})}
                                      >
                                        <AiOutlinePlus />
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                              <div className="row">
                                <div className="col-md-12 p-2">
                                  <FileUpload
                                    accept={["image/png", "image/jpeg","image/webp"]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"image"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Cover Image"
                                    required
                                    tag={true}
                                  />
                                  <Input
                                    type="text"
                                    name="imageTitle"
                                    label="Cover Image Title"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 p-2">
                                  <DateTime
                                    name={`publishDate`}
                                    id={`publishDate`}
                                    label="Publish Date"
                                    required
                                    showTimeSelect={true}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="inpress"
                                    id="inpress"
                                    label="IN PRESS"
                                  />
                                </div>
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="isSponsored"
                                    id="isSponsored"
                                    label="Sponsored Article"
                                  />
                                </div>
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="isLiveBlog"
                                    id="isLiveBlog"
                                    label="Live Blog"
                                  />
                                </div>
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="isMarkUnfollow"
                                    id="isMarkUnfollow"
                                    label="Mark Unfollow"
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                             {/* <RichTextEditor
                             id = ""
                             name = ""
                             label="Content"
                             required
                             maxHeight={600}
                             uploadFile={uploadPublic}
                             getAssetUrl={ASSET_URL}
                              /> */}

                           </div> </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end uvs p-2">
                          <Button
                            className="btn btn-success mr-2"
                            type="button"
                            onClick={() => window.history.go(-1)}
                          >
                            <AiFillCheckCircle />
                            Cancel
                          </Button>
                          <Button
                            className="btn btn-success mr-2"
                            type="submit"
                            onClick={() => onSubmit(values, "Draft", true)}
                          >
                            <AiFillCheckCircle />
                            Preview
                          </Button>
                          <Button
                            className="btn btn-success mr-2"
                            type="submit" disabled={isSubmitted}
                            onClick={() =>
                              onSubmit(values, id ? "Published" : "Draft")
                            }
                          >
                            <AiFillCheckCircle />
                            Save
                          </Button>
                          {id ? (
                            <Button
                              className="btn btn-success"
                              type="submit"
                              onClick={() => onSubmit(values, "Retract")}
                            >
                              <AiFillCheckCircle />
                              Retract
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-success"
                              type="submit" disabled={isSubmitted}
                              onClick={() => onSubmit(values, "Published")}
                            >
                              <AiFillCheckCircle />
                              Publish
                            </Button>
                          )}
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
