import { useState } from "react";
import { useEffect } from "react";
import {
  getBlogList,
  getCategoryList,
  getLayout,
  updateLayout,
} from "./../../../Services/Service";
import { onFailure } from "./../../../util/helper";
import { Select, Input, Form, ImageFromAsset } from "./../../../Components";
import { Button, ListGroup } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";

const CoverStory = () => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState<any>({});
  const [articleList, setArticleList] = useState({ list: [], total: 0 });
  const [layout, setLayout] = useState<any>({});

  const layoutSuccess = ({ data }: any) => {
    setLayout(data);
    setSelectedArticle(data.body.coverStory);
  };

  useEffect(() => {
    getCategoryList(categorySuccess, onFailure);
    getLayout("home", layoutSuccess, onFailure, {
      populate: "body.coverStory",
    });
  }, []);

  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: category.name,
      value: category._id,
    }));
    setCategoryOptions(options);
  };

  const onSuccess = ({ data }: any) => {
    setArticleList(data);
  };

  const updateSuccess = () => {
    Swal.fire({
      title: "Cover Story Successfully Updated",
      icon: "success",
    });
  };

  const saveData = () => {
    // console.log(selectedArticle);
    const payload = {
      coverStory: selectedArticle._id,
    };
    updateLayout(
      { ...layout, body: { ...layout.body, ...payload } },
      updateSuccess,
      onFailure
    );
  };
  return (
    <main className="app-content">
      <div className="tile">
        <div className="app-title">
          <h1>Manage Cover Story</h1>
        </div>
        <Form
          initialValues={{
            categories: "",
            search: "",
          }}
          onSubmit={(values: any) => {
            // console.log(values);
            getBlogList(onSuccess, onFailure, { ...values });
          }}
          render={({ handleChange }: any) => {
            return (
              <>
                <div className="row">
                  <div className="col-md-3">
                    <Select
                      options={categoryOptions}
                      id="parent"
                      name="categories"
                      placeholder="Select Category"
                    />
                  </div>
                  <div className="col-md-5">
                    <Input
                      type="text"
                      name="search"
                      placeholder="Search Article"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Button className="btn btn-success" type="submit">
                      Search
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        />

        <div className="d-flex justify-content-end mt-2">
          <Button className="btn btn-dark" onClick={saveData}>
            Save
          </Button>
        </div>
        <div style={{ maxHeight: "200px", overflow: "auto" }}>
          <ListGroup>
            {articleList.list.map((article: any) => (
              <ListGroup.Item>
                <div className="float-end">
                  <Button
                    className="btn btn-sm"
                    onClick={() => setSelectedArticle(article)}
                  >
                    <FaPlus />
                  </Button>
                </div>
                <div className="row">
                  <div className="col-3">
                    <ImageFromAsset id={article.image} />
                  </div>
                  <div className="col-9">
                    <h6>{article.title}</h6>
                    <p>{article.description}</p>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        {selectedArticle && (
          <div className="m-2 p-2">
            <h4>Selected Cover</h4>

            <div className="row">
              <div className="col-md-4">
                <div style={{ border: 'border: 1px solid rgb(238, 238, 238)', borderRadius: '4px'}} className="p-3">
                  <ImageFromAsset id={selectedArticle?.image} />
                  <h6 className="mt-3">
                    {selectedArticle?.title}
                  </h6>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default CoverStory;
