export const onFailure = (error: any) => {
  // eslint-disable-next-line no-console
  console.log(error);
};

export const disabled = (err: any) => {
  if (err) {
    return Object.keys(err).length === 0 ? false : true;
  }
  return true;
};
