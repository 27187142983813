import { Button } from "react-bootstrap";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { uploadPublic } from "../../../Services/FileUploadService";
import { FieldArray } from "formik";
import { createCms, getCms, getUserList, updateCms } from "../../../Services/Service";
import { Media, MetaTags } from "../../../interfaces";
import { ASSET_URL, SITE_URL } from "../../../constant";
import { onFailure } from "./../../../util/helper";
import Swal from "sweetalert2";
import { BackButton } from "./../../../Components/BackButton";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "./../../../Components/Select";
import { getPopulatedCategoryList } from "./../../../Services/Service";
import omit from "lodash/omit";
import {
  Form,
  Input,
  FileUpload,
  DateTime,
  CheckBox,
} from "./../../../Components";
import { Article_Management } from "../../../RouteConstant";

export interface BlogFormValues {
  title: string;
  seo?: {
    title?: string;
    description?: string;
    metaTags?: MetaTags[];
  };
  type: string;
  videos: Media[];
  publishDate:Date;
}

export const CreateVideo = () => {
  let { id }: any = useParams();
  const history = useHistory();
  

  const [categoryOption, setCategoryOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [initialValues, setInitialValues] = useState<BlogFormValues>({
    title: "",
    type: "Video",
    seo: {
      title: "",
      description: "",
    },
    videos: [
      {
        url: "",
        description: "",
      },
    ],
    publishDate:new Date()
  });

  const onGetSuccess = (res: any) => {
    const data: any = omit(res.data, [
      "views",
      "createdAt",
      "updatedAt",
      "slug",
    ]);
    data.categories = res.data.categories[0]
    setInitialValues(data);
  };

  useEffect(() => {
    if (id) {
      getCms(id, onGetSuccess, onFailure);
    }
    getPopulatedCategoryList(categorySuccess, onFailure);
    getUserList(userSuccess,onFailure,{all:true})
  }, []);

  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: `${(category.parent && category.parent != null && category.parent.length > 0) ? `${category.parent[0]?.name} |` :'' }  ${category.name}`,
      value: category._id,
    }));
    setCategoryOptions(options);
  };
  const userSuccess = (data: any) => {
    const options = data.data.list.map((user: any) => ({
      label: user.name,
      value: user._id,
    }));
    setUsers(options);
  };
  const onSuccess = (res: any) => {
    Swal.fire({
      title:id? "Blog Updated Successfully": "Blog Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Article_Management);
    });
  };
  const onSubmit = (values:any,status:any, isPreview=false) =>{
    let payload:any = {};
    if(id){
      payload._id = id
    }
    if(values?._id){
      payload._id = values._id
    }
    payload.image = values.image;
    payload.categories = [values.categories];
    payload.description = values.description;
    payload.publishDate = values.publishDate;;
    payload.seo = values.seo;
    payload.title = values.title;
    payload.user = values.user
    payload.videos = values.videos;
    payload.status = status
    payload.type = "Video"
    payload.isSponsored = values.isSponsored;
    payload.isMarkUnfollow = values.isMarkUnfollow;
    if(values.canonicalUrl){
      payload.canonicalUrl = values.canonicalUrl
    }
    if(values.imageTitle){
      payload.imageTitle = values.imageTitle
    }
    if(values.editedBy){
      payload.editedBy = values.editedBy;
    }
    let callback:any = onSuccess
    if(isPreview && payload?._id){
      callback = (res:any) => onShowPreviewUpdate(res,payload)
    }else if(isPreview){
      callback = onShowPreviewCreate
    }

    if (payload?._id) {
      updateCms(payload, callback, onFailure);
    } else {
      createCms(payload, callback, onFailure);
    }
  }
  const onShowPreviewCreate = (res:any) =>{
    if(res.data._id){
      setInitialValues(res.data)
    }
    window.open(`${SITE_URL}/preview/${ res.data._id}`,"_blank")
  }
  const onShowPreviewUpdate = (res:any,payload:any) =>{
    window.open(`${SITE_URL}/preview/${ payload._id}`,"_blank")
  }
  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="tile">
          <div>
            <div className="app-title">
              {id && <h1>Update Video</h1>}
              {!id && <h1>Add Video</h1>}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  render={({ handleChange, values }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row ">
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="title"
                                    label="TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="englishTitle"
                                    label="Englis Title"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Select
                                    // isMulti={true}
                                    name="categories"
                                    id="categories"
                                    options={categoryOption}
                                    label="Select Cateogries"
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Select
                                    name="user"
                                    id="user"
                                    options={users}
                                    label="Select Author"
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Select
                                    name="editedBy"
                                    id="editedBy"
                                    options={users}
                                    label="Select Editor"
                                    // required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`description`}
                                    label="Short Description"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name={`canonicalUrl`}
                                    label="Canonical Url"
                                    onChange={handleChange}
                                    // required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.title"
                                    label="SEO TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.description"
                                    label="SEO DESCRIPTION"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    name="seo.metaTags[0].content"
                                    id="seo.metaTags[0].content"
                                    label="Keywords"
                                    onChange={handleChange}
                                    maxLength="500"
                                  />
                                  <small>500 character limit</small>
                                </div>
                              </div>
                              <FieldArray name="videos">
                                {({ remove, push }) => (
                                  <div className="row ">
                                    {values?.videos?.length > 0 &&
                                      values.videos.map(
                                        (value: any, index: number) => (
                                          <div
                                            key={index}
                                            className="page-section mt-2 position-relative"
                                          >
                                            <div className="col-md-12 p-2">
                                              <Input
                                                type="text"
                                                name={`videos.${index}.url`}
                                                label="YOUTUBE VIDEO URL"
                                                onChange={handleChange}
                                              />
                                            </div>

                                            <div className="col-md-12 p-2">
                                              <FileUpload
                                                accept={["video/mp4"]}
                                                type="single"
                                                maxSize={"1gb"}
                                                name={`videos.${index}.file`}
                                                upload={uploadPublic}
                                                assetUrl={ASSET_URL}
                                                label="UPLOAD VIDEO"
                                              />
                                            </div>

                                            <div className="col-md-12 p-2">
                                              {/* <Input
                                                type="text"
                                                name={`videos.${index}.description`}
                                                label="CAPTION"
                                                onChange={handleChange}
                                              /> */}
                                            </div>

                                            {index > 0 && (
                                              <div className="icon-button text-danger">
                                                <MdDelete
                                                  onClick={() => remove(index)}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                    <div className="d-flex justify-content-end uvs p-2">
                                      <Button
                                        size="lg"
                                        variant="success"
                                        onClick={() => push({})}
                                      >
                                        <AiOutlinePlus />
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                              <div className="row">
                                <div className="col-md-6 p-2">
                                  <DateTime
                                    name={`publishDate`}
                                    id={`publishDate`}
                                    label="Publish Date"
                                    required
                                    dateFormat="yyyy-MM-dd"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 p-2">
                                  <FileUpload
                                    accept={["image/png", "image/jpeg","image/webp"]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"image"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Cover Image"
                                  />
                                  <Input
                                    type="text"
                                    name="imageTitle"
                                    label="Cover Image Title"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="isSponsored"
                                    id="isSponsored"
                                    label="Sponsored Article"
                                  />
                                </div>
                                <div className="col-md-2 p-2">
                                  <CheckBox
                                    name="isMarkUnfollow"
                                    id="isMarkUnfollow"
                                    label="Mark Unfollow"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end uvs p-2">
                          <Button className="btn btn-success mr-2" type="button" onClick={()=>window.history.go(-1)}>
                            <AiFillCheckCircle />
                            Cancel
                          </Button>
                          <Button className="btn btn-success mr-2" type="submit" onClick={ ()=> onSubmit(values,"Draft",true)}>
                            <AiFillCheckCircle />
                            Preview
                          </Button>
                          <Button className="btn btn-success mr-2" type="submit" onClick={ ()=> onSubmit(values,id? "Published": "Draft")}>
                            <AiFillCheckCircle />
                            Save
                          </Button>
                          {id ? (
                            <Button className="btn btn-success" type="submit" onClick={ ()=> onSubmit(values,"Retract")}>
                              <AiFillCheckCircle />
                              Retract
                            </Button>
                          ) : (
                            <Button className="btn btn-success" type="submit" onClick={ ()=> onSubmit(values,"Published")}>
                              <AiFillCheckCircle />
                              Publish
                            </Button>
                          )}
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
