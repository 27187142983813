// import client from "client";
import { BASE_URL } from "../constant";
import { client } from "./client";

export const login = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/auth/login`, data)
    .then(({ data }: any) => {
      localStorage.setItem("token", data.data.token);
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const UserToken = () => localStorage.getItem("token");

export const config = () => ({
  headers: {
    Authorization: `Bearer ${UserToken()}`,
  },
});

export const getUserDetail = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/user/me`, {
      params: { ...params },
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getUserList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/user`, { params: params, ...config() })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const createUser = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/user`, data, config())
    .then(({ data }) => {
      // localStorage.setItem("token", data.data.token);
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const updateUser = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/user`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getUser = (id: string, onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/user/${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getClientList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/client`, { params: params, ...config() })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const createClient = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/client`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const updateClient = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/client/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getClient = (id: string, onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/client/${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getVisualstoriesList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}

) => {
  client
    .get(`${BASE_URL}/visualstories/`, { params: params, ...config() })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const createVisualstories = (
  data: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/visualstories/`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const updateVisualstory = (
  data: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .patch(`${BASE_URL}/visualstories/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });

};
export const getVisualstory = (
  id: string,
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/visualstories/${id}`, { ...config(), params })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });

};
export const getVisualCateoryList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/visualcategory/`, { params: params, ...config() })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const createVisualCategory = (

  onSuccess: any,
  onFailure: any,
  data: any,
) => {
  client
    .post(`${BASE_URL}/visualcategory/`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};


export const getAdvertisementList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/advertisement`, { params: params, ...config() })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const createAdvertisement = (
  data: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/advertisement`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const updateAdvertisement = (
  data: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .patch(`${BASE_URL}/advertisement/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getAdvertisement = (
  id: string,
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/advertisement/${id}`, { ...config(), params })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getCategoryList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/category/`, {
      params: params,
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getPopulatedCategoryList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/category/list`, {
      params: params,
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const createCategory = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/category/create`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const updateCategory = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/category/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getRoleList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/role`, {
      params,
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const createRole = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/role/create`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getRole = (id: any, onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/role/${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const updateRole = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/role/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getEpaperList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/epaper`, {
      params: { ...params, isRecent: true },
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const createEpaper = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/epaper/create`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getEpaper = (id: any, onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/epaper/detail/${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const updateEpaper = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/epaper/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getPageList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/cms`, {
      params: { ...params, type: "page", isRecent: true },
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getBlogList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/cms`, {
      params: { ...params, isRecent: true },
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const createCms = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/cms/create`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getCmsNotification = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/cms/notification`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getCms = (
  id: any,
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/cms/detail/${id}`, {
      params: params,
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const updateCms = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/cms/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getMenuList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/menu`, {
      params: params,
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getMenu = (onSuccess: any, onFailure: any, id: string) => {
  client
    .get(`${BASE_URL}/menu/${id}`, {
      params: {},
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const addMenu = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/menu/create`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const updateMenu = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/menu/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getLayout = (
  id: any,
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/layout/${id}`, {
      params,
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const updateLayout = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/layout/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getAsset = (id: string, onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/asset/${id}`, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getBreakingNewsList = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .get(`${BASE_URL}/breaking-news`, { params: params, ...config() })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const createBreakingNews = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/breaking-news`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const updateBreakingNews = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/breaking-news/${data._id}`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const getLiveBlogCommentryList =(onSuccess:any,onFailure:any,params: any = {})=>{
  client.get(`${BASE_URL}/liveblog`,{params,...config()})
  .then(({data})=>{onSuccess(data)})
  .catch((err)=>{onFailure(err)})
};
export const getLiveCommentry = (
  id:any,
  onSuccess:any,
  onFailure:any,
  params:any ={})=>{
    client.get(`${BASE_URL}/liveblog/${id}`,{...params,...config()})
    .then(({data})=>onSuccess(data))
    .catch((err)=>onFailure(err))
  };
export  const createLiveBlogComment = (
  data:any,
  onSuccess:any,
  onFailure:any
)=>{
client.post(`${BASE_URL}/liveblog`,data,config())
.then(({data})=>{onSuccess(data)})
.catch((err)=>{onFailure(err)})
};

export  const updateLiveBlogComment = (
  data:any,
  onSuccess:any,
  onFailure:any
)=>{
client.post(`${BASE_URL}/liveblog/${data._id}`,data,config())
.then(({data})=>{onSuccess(data)})
.catch((err)=>{onFailure(err)})
};
export const deleteArticleLiveComment = (id:any,onSuccess:any,onFailure:any)=>{
  client
  .delete(`${BASE_URL}/liveblog/${id}`,config())
  .then(({data})=>onSuccess(data))
  .catch((err)=>onFailure(err))
};
