import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { uploadPublic } from "../../../Services/FileUploadService";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FieldProps, Field, FieldArray } from "formik";
import { HTML } from "../../../interfaces";
import { ASSET_URL, HTML_FIELDS } from "../../../constant";
import { BackButton } from "../../../Components/BackButton";
import { onFailure } from "../../../util/helper";
import { createCms, getCms, updateCms } from "../../../Services/Service";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Label, FileUpload } from "./../../../Components";
import omit from "lodash/omit";

export interface PageFormValues {
  title: string;
  type: string;
  body: HTML[];
}

const CreatePage = () => {
  let { id }: any = useParams();

  const [initialValues, setInitialValues] = useState<PageFormValues>({
    title: "",
    type: "Page",
    body: [HTML_FIELDS],
  });

  const onGetSuccess = (res: any) => {
    const data: any = omit(res.data, [
      "views",
      "createdAt",
      "updatedAt",
      "slug",
    ]);
    setInitialValues(data);
  };

  useEffect(() => {
    if (id) {
      getCms(id, onGetSuccess, onFailure);
    }
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "Blog Added Successfully",
      icon: "success",
    });
  };
  return (
    <>
      <main className="app-content" id="app">
        <BackButton />

        <div className="tile">
          <div>
            <div className="app-title">
              {id && <h1>Update Page</h1>}
              {!id && <h1>Add Page</h1>}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  onSubmit={(values: any) => {
                    if (id) {
                      updateCms(values, onSuccess, onFailure);
                    } else {
                      createCms(values, onSuccess, onFailure);
                    }
                  }}
                  render={({ handleChange, values }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="col-md-12 p-2">
                            <Input
                              type="text"
                              name="title"
                              label="TITLE"
                              onChange={handleChange}
                            />
                          </div>
                          <FieldArray name="body">
                            {({ remove, push }) => (
                              <>
                                {values?.body?.length > 0 &&
                                  values.body.map(
                                    (value: any, index: number) => (
                                      <>
                                        <div className="col-md-12 p-2">
                                          <Input
                                            type="text"
                                            name={`body.${index}.title`}
                                            label="HEADER"
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <div className="col-md-12 p-2">
                                          <Label label="Content" />
                                          <Field
                                            name={`body.${index}.body`}
                                            render={({
                                              form,
                                              field,
                                            }: FieldProps<number | string>) => {
                                              return (
                                                <CKEditor
                                                  editor={ClassicEditor}
                                                  data={field.value}
                                                  config={{
                                                    placeholder:
                                                      "Write here Description",
                                                  }}
                                                  onChange={(
                                                    event: any,
                                                    editor: any
                                                  ) => {
                                                    form.setFieldValue(
                                                      field.name,
                                                      editor.getData()
                                                    );
                                                  }}
                                                />
                                              );
                                            }}
                                          />
                                        </div>
                                      </>
                                    )
                                  )}
                              </>
                            )}
                          </FieldArray>

                          <div className="col-md-12 p-2">
                            <FileUpload
                              accept={["image/png", "image/jpeg"]}
                              type="single"
                              maxSize={"3mb"}
                              name={"image"}
                              upload={uploadPublic}
                              assetUrl={ASSET_URL}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-end uvs">
                          <Button className="btn btn-warning" type="submit">
                            <AiFillCheckCircle />
                            SAVE DRAFT
                          </Button>
                          &nbsp;&nbsp;
                          <Button className="btn btn-success" type="submit">
                            <AiFillCheckCircle />
                            SAVE AND PUBLISH
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default CreatePage;
