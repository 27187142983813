import { Modal } from 'react-bootstrap';
import * as React from 'react';

interface ModelType {
  title?: string;
  size?: 'sm' | 'lg' | 'xl';
  show: boolean;
  centered: boolean;
  handleClose: () => void;
  children: any;
  backdrop?: boolean | 'static';
}

export const Model = ({
  title,
  size,
  show,
  handleClose,
  centered = true,
  children,
  backdrop = true,
}: ModelType) => {
  return (
    <>
      <Modal
        size={size}
        centered={centered}
        show={show}
        onHide={handleClose}
        backdrop={backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};
