import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Manage_User } from "../../RouteConstant";
import { getRoleList, getUser } from "../../Services/Service";
import Swal from "sweetalert2";
import { onFailure } from "../../util/helper";
import { ErrorBlock, BackButton, Form, Input, Select } from "../../Components";
import { useParams } from "react-router-dom";
import { updateUser } from "./../../Services/Service";
import { UserPasswordSchema } from "../../util/ValidationSchema";

const ChangeUserPassword = () => {
  let { id }: any = useParams();

  const [error, setError] = useState();

  const [initialValues, setInitialValue] = useState({});

  const history = useHistory();

  const onGetSuccess = (res: any) => {
    setInitialValue(res.data);
  };

  useEffect(() => {
    getUser(id, onGetSuccess, onFailure);
  }, []);

  const onSuccess = (res: any) => {
    Swal.fire({
      title: "User Added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Manage_User);
    });
  };

  const userFailure = (error: any) => {
    if (error.status == 400) {
      setError(error.data.message.msg.split(":")[1]);
    }
  };

  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="tile">
              <div className="w-100">
                <h3 className="text-center">{"Change Password"}</h3>
                {error && <ErrorBlock>{error}</ErrorBlock>}

                <Form
                  initialValues={initialValues}
                  validationSchema={UserPasswordSchema}
                  onSubmit={(values: any) => {
                    const payload = {
                      _id: id,
                      confirmPassword: values.confirmPassword,
                      password: values.password,
                    };
                    updateUser(payload, onSuccess, userFailure);
                  }}
                  render={({ handleChange }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="email">Email</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    name="email"
                                    type="email"
                                    disabled={true}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="fname">Name</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="password">Password</label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row p-2">
                                <div className="col-md-2">
                                  <label htmlFor="confirmPassword">
                                    Confirm Password
                                  </label>
                                </div>
                                <div className="col-md-10">
                                  <Input
                                    type="password"
                                    name="confirmPassword"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end">
                          <Button className="btn btn-primary " type="submit">
                            <AiFillCheckCircle />
                            Update
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default ChangeUserPassword;
