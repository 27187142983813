import { Form } from "./../../../Components/Form";
import { useState, useEffect } from "react";
import { Input } from "./../../../Components/input/input";
import { Select } from "./../../../Components/Select";
import { getBlogList, getCategoryList } from "./../../../Services/Service";
import { onFailure } from "./../../../util/helper";
import { Button, ButtonGroup } from "react-bootstrap";
import { AiFillFilter } from "react-icons/ai";

const FiterForm = ({ setData, listParams, setFilters }: any) => {
  const defaultFormValues = {
    search: "",
    categories: [],
    date:''
  };
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [showFilter, setShowFilter] = useState(false);
  const [categoryOption, setCategoryOptions] = useState([]);

  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: category.name,
      value: category._id,
    }));
    setCategoryOptions(options);
  };

  const onSuccess = (data: any) => {
    setData(data.data);
  };

  useEffect(() => {
    getCategoryList(categorySuccess, onFailure);
  }, []);

  const search = (query: any) => {
    setFilters(query)
    getBlogList(onSuccess, onFailure, { ...listParams, ...query });
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          variant="outline-info"
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <AiFillFilter />
        </Button>
      </div>
      {showFilter && (
        <Form
          initialValues={initialValues}
          onSubmit={(values: any) => {
            // console.log(values);
            search(values);
          }}
          render={({ handleChange, resetForm, submitForm }: any) => {
            return (
              <div className="row">
                <div className="col-md-3 p-2">
                  <Input
                    type="text"
                    name="search"
                    // label="TITLE"
                    placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 p-2">
                  <Select
                    isMulti={true}
                    name="categories"
                    id="categories"
                    options={categoryOption}
                    placeholder="Select Category"
                  />
                </div>
                <div className="col-md-3 p-2">
                <Input
                    type="date"
                    name="date"
                    // label="TITLE"
                    // placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 p-2">
                  <ButtonGroup>
                    <Button className="btn btn-success" type="submit">
                      Apply
                    </Button>
                    <Button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={() => {
                        resetForm();
                        submitForm();
                      }}
                    >
                      Reset
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default FiterForm;
