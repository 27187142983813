import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  Form,
  Input,
  TextArea,
  FileUpload,
  Select,
  Model,
  TagInputField,
} from "../../Components";
import { uploadPublic } from "../../Services/FileUploadService";
import { createCategory } from "../../Services/Service";
import Swal from "sweetalert2";
import { getCategoryList } from "./../../Services/Service";
import { onFailure } from "../../util/helper";
import { useEffect } from "react";
import { useState } from "react";
import { OptionList } from "../../Components/Select";
import { ASSET_URL, META_TAGS } from "../../constant";

interface CategoryInterface {
  show: boolean;
  handleClose: () => void;
  setCategoryData: (a: any, b: any) => void;
  setopenModel: (a: boolean) => void;
  category: string[];
}

const AddCategoryModal = ({
  show,
  handleClose,
  setopenModel,
  setCategoryData,
  category,
}: CategoryInterface) => {
  const initialValues = {
    name: "",
    description: "",
    
  };

  const [options, setOptions] = useState<OptionList>([
    {
      label: "",
      value: "",
    },
  ]);

  useEffect(() => {
    const option: OptionList = category.map((cat: any) => ({
      label: cat.name,
      value: cat._id,
    }));
    setOptions(option);
  }, []);

  const onSuccess = (res: any) => {
    // eslint-disable-next-line no-console

    Swal.fire({
      title: "Category Added Successfully",
      icon: "success",
    });

    setopenModel(false);
    getCategoryList(setCategoryData, () => {});
  };

  return (
    <>
      <Model
        centered={true}
        size="lg"
        title="Add Category"
        show={show}
        handleClose={handleClose}
        backdrop="static"
      >
        <div className="row">
          <div className="col-md-12">
            <Form
              initialValues={initialValues}
              onSubmit={(values: any) =>{
                createCategory(values, onSuccess, onFailure)
              }}
              render={({ handleChange, submitForm }: any) => {
                return (
                  <>
                    <div className="row">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="row ">
                            <div className="col-md-12">
                              <Input
                                type="text"
                                name="name"
                                label="Name"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-12 p-2">
                              <Input
                                type="text"
                                name="englishTitle"
                                label="English Name"
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="seo.title"
                                label="SEO title"
                                onChange={handleChange}
                                // required
                              />
                            </div>
                            <div className="col-md-12 ">
                              <Input
                                type="text"
                                name="seo.description"
                                label="SEO Description"
                                onChange={handleChange}
                                // required
                              />
                            </div>
                            <div className="col-md-12 ">
                              <Input
                                name="seo.metaTags[0].property"
                                id="seo.metaTags[0].property"
                                label="Keywords"
                                onChange={handleChange}
                                // required
                                />
                            </div>
                            <div className="col-md-12 ">
                              <Input
                                  name="seo.metaTags[0].content"
                                  id="seo.metaTags[0].content"
                                  label="Content"
                                  onChange={handleChange}
                                  // required
                                  />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 ">
                              <Select
                                options={options}
                                isMulti={true}
                                id="parent"
                                name="parent"
                                label="Parent Category"
                                placeholder="Multi Select Parent"
                              />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 ">
                              <TextArea
                                name="description"
                                id="description"
                                label="Description"
                                row={3}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 ">
                              <FileUpload
                                accept={["image/png", "image/jpeg"]}
                                type="single"
                                maxSize={"3mb"}
                                name={"image"}
                                upload={uploadPublic}
                                assetUrl={ASSET_URL}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center uvs">
                      <Button className="btn btn-info" type="submit">
                        <AiFillCheckCircle />
                        Create Category
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </Model>
    </>
  );
};
export default AddCategoryModal;
