import React, { useState,useRef } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SMALL_PAGE_SIZE } from "../../../constant";
import { Create_Ad, Edit_Ad } from "../../../RouteConstant";
import { getAdvertisementList, getCategoryList, getClientList, updateAdvertisement } from "../../../Services/Service";
import { onFailure } from "../../../util/helper";
import { Form, Input, PaginatedTable, Paginator, Select } from "../../../Components";
import { ActionInterface } from "../../../Components/BasicTable";
import moment from "moment";
import { AiFillFilter } from "react-icons/ai";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import omit from "lodash/omit";

const MangageAdvertisement = () => {
  const [data, setData] = React.useState({ list: [], total: 0 });
  const [csvData, setCsvData] = React.useState({ list: [], total: 0 });
  const history = useHistory();
  const [currentPage, setPage] = useState(1);
  const defaultFormValues = {
    search: "",
    categories: [],
    date:'',
    client:''
  };
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [showFilter, setShowFilter] = useState(false);
  const [categoryOption, setCategoryOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const listParams = {
    populate: "user_f=name, client_f=name, categories_f=name",
  };
  const csvLink: any = useRef();
  const onSuccess = (data: any) => {
    const ads = data.data.list.map((i:any)=>{
      const ctr = ((i.clicks*100)/i.views).toFixed(2)
      const category = i.categories.map((cat:any)=>cat.name).join(', ');
      return {...i,ctr,category}
    })
    data.data.list = ads
      setData(data.data);
  };
  const clientSuccess = (data: any) => {
    const options = data.data.list.map((user: any) => ({
      label: user.name,
      value: user._id,
    }));
    setUsers(options);
  };
  React.useEffect(() => {
    getAdvertisementList(onSuccess, onFailure, listParams);
    getCategoryList(categorySuccess, onFailure);
    getClientList(clientSuccess, onFailure);
  }, []);

  const onSuccessUpdate = (res: any) => {
    Swal.fire({
      title: "Advertisement Updated Successfully",
      icon: "success",
    }).then(() => {
      getAdvertisementList(onSuccess, onFailure, { ...listParams, page: currentPage });
    });
  };
  const headings = [
    {
      key: "title",
      text: "Title",
    },
    {
      key: "description",
      text: "Description",
    },
    {
      key: "url",
      text: "URL",
    },
    {
      key: "category",
      text: "Categories",
    },
    {
      key: "startDate",
      text: "Start Date",
    },
    {
      key: "endDate",
      text: "End Date",
    },
    {
      key: "client.name",
      text: "Client",
    },
    // {
    //   key: "isFeatured",
    //   text: "Show in Home",
    // },
    {
      key: "position",
      text: "Position",
    },
    {
      key: "maxImpressionAllowed",
      text: "Impression Allowed",
    },
    {
      key: "views",
      text: "Views Received",
    },
    {
      key: "clicks",
      text: "Clicks Received",
    },
    {
      key:'ctr',
      text: "CTR (in %)",
    },
    {
      key: "user.name",
      text: "Added By",
    },
  ];

  const formatters = {
    categories: (data: any) => data.map((cat: any) => cat.name).join(","),
    startDate: (data: any) => moment(data).format("DD/MM/YYYY HH:SS"),
    endDate: (data: any) => moment(data).format("DD/MM/YYYY HH:SS"),
  };

  const pageChange = (page: any) => {
    getAdvertisementList(onSuccess, onFailure, { ...listParams, page: page });
    setPage(page);
  };
  const search = (query: any) => {
    getAdvertisementList(onSuccess, onFailure, { ...listParams, ...query });
    setInitialValues(query)
  };
  const categorySuccess = (data: any) => {
    const options = data.data.map((category: any) => ({
      label: category.name,
      value: category._id,
    }));
    setCategoryOptions(options);
  };
 
  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => history.push(Edit_Ad.replace(":id", data._id)),
    },
    {
      text: "Update",
      conditionalText:(data: any)=> (data.active ? "Deactive" : "Active"),
      trigger: (data: any) => {
        data.active = !data.active;
        updateAdvertisement(data, onSuccessUpdate, onFailure);
      },
    },
  ];
  const getTransactionData = async () => {
    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    getAdvertisementList(onSuccessDownload, onFailure, {
      ...listParams,
      pageSize: 5000,
      ...initialValues
    });
  };
  const onSuccessDownload = (res:any)=>{
    const list:any = res.data.list.map((i: any) => {
      const article = omit(i, [
        "images",
        "active",
        "isFeatured",
        "position",
        "updatedAt",
        "videos",
        "user",
        "defaultPage"
      ]);
      article.ctr = ((i.clicks*100)/i.views).toFixed(2)
      article.client = i.client?.name;
      article.categories = i.categories.map((cat:any)=>cat.name).join(', ');
      return article;
    });
    
    setCsvData({list:list,total:0});
    csvLink.current.link.click();
  }
    return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Advertisement Management</h1>
          </div>
          <Button onClick={getTransactionData}>Export CSV</Button>
          
          <CSVLink
            data={csvData.list}
            filename="Ads.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          /> 
          <Button
            onClick={() => history.push(Create_Ad)}
            className="btn btn-success"
          >
            Add Advertisement
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body table-responsive">
              <div className="d-flex justify-content-end">
        <Button
          variant="outline-info"
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <AiFillFilter />
        </Button>
      </div>
      {showFilter && (
        <Form
          initialValues={initialValues}
          onSubmit={(values: any) => {
            // console.log(values);
            search(values);
          }}
          render={({ handleChange, resetForm, submitForm }: any) => {
            return (
              <div className="row">
                <div className="col-md-3 p-2">
                  <Input
                    type="text"
                    name="search"
                    // label="TITLE"
                    placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 p-2">
                  <Select
                    isMulti={true}
                    name="categories"
                    id="categories"
                    options={categoryOption}
                    placeholder="Select Category"
                  />
                </div>
                <div className="col-md-2 p-2">
                  <Select
                    name="client"
                    id="client"
                    options={users}
                    placeholder="Select Cient"
                  />
                </div>
                <div className="col-md-2 p-2">
                <Input
                    type="date"
                    name="date"
                    // label="TITLE"
                    // placeholder="Enter Text to search"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-2 p-2">
                  <ButtonGroup>
                    <Button className="btn btn-success" type="submit">
                      Apply
                    </Button>
                    <Button
                      className="btn btn-outline-danger"
                      type="button"
                      onClick={() => {
                        resetForm(setInitialValues({search: "",
                        categories: [],
                        date:'',
                        client:''}));
                        submitForm();
                      }}
                    >
                      Reset
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );
          }}
        />
      )}
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  actionItems={actionItems}
                  formatters={formatters}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page) => pageChange(page)}
                      page={currentPage}
                    />
                  )}
                ></PaginatedTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default MangageAdvertisement;
