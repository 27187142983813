import moment from 'moment';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { PaginatedTable, Paginator } from '../../Components';
import { ActionInterface } from '../../Components/BasicTable';
import {  SMALL_PAGE_SIZE } from '../../constant';
import { LiveBlog_Create } from '../../RouteConstant';
import { getBlogList } from '../../Services/Service';
import { onFailure } from '../../util/helper';
const MangeLiveBlog = () => {
    const history = useHistory();
    const [data, setData] = useState({ list: [], total: 0 });

    const headings = [
        {
            key: "title",
            text: "ArticleName",
        },
        {
            key: "createdAt",
            text: "CreatedAt",
        },
        {
            key: "categories[0]",
            text: "Category",
        },
    ]
    const onSuccess = (Res:any)=>{
       setData(Res.data);
    }

    React.useEffect(()=>{
        getBlogList(onSuccess,onFailure,{isLiveBlog:true});
    },[])
    const formatters = {
        createdAt: (data: any) => moment(data).format("DD/MM/YYYY HH:SS")
    }
    const actionItems:ActionInterface[]  = [
        {
            text:"StartBloging",
            trigger:(data:any)=> history.push(LiveBlog_Create.replace(":id",`${data._id}`)),
        }
    ];

    return (<>
        <main className='app-content' id='app'>
            <div className='app-title'>
                <h2>LiveBlog Management</h2>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <PaginatedTable
                        headings={headings}
                        data={data.list}
                        totalSize={data.total}
                        pageSize={SMALL_PAGE_SIZE}
                        formatters={formatters}
                        actionItems={actionItems}
                        renderPager={() => (
                            <Paginator
                                totalPages={Math.ceil(data.total/SMALL_PAGE_SIZE)}
                                onPageChange={() => { }}
                                page={1}
                            />
                        )}
                    >

                    </PaginatedTable>
                </div>
            </div>
        </main>
    </>
    )
}

export default MangeLiveBlog;