import React from "react";
import { useField } from "formik";
import { CheckboxProps } from "./checkbox.types";
import { ErrorMessage } from "../ErrorMessage";
import styled from "styled-components";

const CustomCheckbox = styled.div`
  // .custom-control-input:checked ~ .custom-control-label::before {
  //   border-color: #239f87;
  //   background-color: #239f87;
  // }
  // &:focus-within .custom-control-label::before {
  //   box-shadow: 0 0 0 1px #239f87;
  // }
`;

export const CheckBox = ({
  id,
  className,
  name,
  isArray,
  label,
  value,
  disabled,
}: CheckboxProps) => {
  const [field, meta, helpers] = useField({ name });
  const _id = id || name;
  let isChecked = false;
  const fieldValue = meta.value;

  if (isArray) {
    isChecked =
      fieldValue && fieldValue.length ? fieldValue.includes(value) : false;
  } else {
    isChecked = !!fieldValue;
  }

  const handleChange = () => {
    if (isArray) {
      if (isChecked) {
        helpers.setValue(fieldValue.filter((v: any) => v !== value));
      } else {
        helpers.setValue([...fieldValue, value]);
      }
    } else {
      helpers.setValue(!isChecked);
    }
  };

  return (
    <>
      <CustomCheckbox className={`${className} custom-control custom-checkbox`}>
        <input
          type={"checkbox"}
          {...field}
          id={_id}
          checked={isChecked}
          onChange={handleChange}
          className="custom-control-input"
          disabled={disabled}
        />
        <label htmlFor={_id} className={"custom-control-label"}>
          {label}
        </label>
      </CustomCheckbox>
      <ErrorMessage meta={meta} />
    </>
  );
};
